import { Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";
import { Creators as ReportActions } from "../../../store/ducks/report";

import {
  Checkbox,
  Col,
  DatePicker,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import moment from "moment";
import { exportAnimalWeightReport } from "../../../services/animalWeightHistoryService";
import { getLotIndexDropDown } from "../../../services/lotService";
import fileReader from "../../../utils/fileReader";
import ButtonStandard from "../../utils/button";
import { Container } from "./styles";

// import { Container } from './styles';

const INITIAL_FORM = {
  startDate: null,
  endDate: null,
  gender: "B",
  minAge: null,
  maxAge: null,
  status: "T",
  supplierId: null,
  supplierName: null,
  lotId: null,
  lotName: null,
  listAnimalsWithoutWeight: false,
  showProjectedWeight: false,
};

const validateSchema = Yup.object({
  status: Yup.string().required(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
  gender: Yup.string().required(),
  minAge: Yup.number().min(0).max(9999).nullable(),
  maxAge: Yup.number().moreThan("minAge").min(0).max(9999).nullable(),
});

const ReportAnimalWeightHistoryModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [lots, setLots] = useState([]);
  const formikRef = useRef(null);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { isModalAnimalWeightHistoryReportVisible } = useSelector(
    (state) => state.report
  );

  const {
    listDrowpDown: suppliers,
    isLoadingDropDown: isLoadingDropDownSuppliers,
  } = useSelector((state) => state.supplier);

  const dispatch = useDispatch();

  const fetchLots = useCallback(async () => {
    const {
      data: { results },
    } = await getLotIndexDropDown({
      groupId,
      farmId,
    });
    setLots(
      results.filter(
        (lot) =>
          (lot.status === "Active" ||
            lot.status === "A" ||
            lot.status === "Pending" ||
            lot.status === "P") &&
          lot.currentAmountAnimals > 0
      )
    );
  }, [groupId, farmId]);

  const closeModal = useCallback(() => {
    formikRef.current.resetForm(INITIAL_FORM);
    dispatch(ReportActions.hideModal());
  }, [dispatch]);

  const handleSubmit = async (values, actions) => {
    notification.info({
      message:
        "O seu relatório está sendo preparado e logo será feito o download!",
    });
    setTimeout(() => {
      closeModal();
    }, 5);
    try {
      const { data } = await exportAnimalWeightReport({
        groupId,
        farmId,
        body: values,
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `analise_pesagens.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      notification.success({
        message: "Relatório de análise de pesagem gerado com sucesso.",
      });
    } catch (error) {
      if (error?.response) {
        try {
          const { data } = error.response;
          // Read file
          const file = await fileReader(data);

          if (typeof file === "string") {
            // Parse content and retrieve 'message'
            const result = JSON.parse(file);
            if (result.code === 5003) {
              notification.error({
                message: "Atenção! Não existem pesagens no período informado.",
              });
            } else {
              notification.error({
                message: "Erro ao tentar gerar relatório de análise de pesagem",
              });
            }
          }
        } catch (readError) {
          notification.error({
            message: "Erro ao tentar gerar relatório de análise de pesagem",
          });
        }
      } else {
        notification.error({
          message: "Erro ao tentar gerar relatório de análise de pesagem",
        });
      }
    } finally {
    }
  };

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        fetchLots();
        dispatch(
          SupplierActions.getDropdownSuppliers(
            groupId,
            farmId,
            "CattleFarmer",
            true
          )
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }

    if (isModalAnimalWeightHistoryReportVisible) {
      fetch();
    }
  }, [
    isModalAnimalWeightHistoryReportVisible,
    dispatch,
    farmId,
    groupId,
    fetchLots,
  ]);

  return (
    <Modal
      visible={isModalAnimalWeightHistoryReportVisible}
      centered
      closable={false}
      footer={null}
      title={translation.animalWeightHistory.modalExportReport.title}
    >
      <Container>
        <Spin spinning={isLoading}>
          <Formik
            ref={formikRef}
            initialValues={INITIAL_FORM}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={validateSchema}
            render={({
              values,
              setFieldValue,
              errors,
              submitCount,
              handleSubmit,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="body">
                  {Object.entries(errors).length > 0 && submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={24}>
                      <Row>
                        <label
                          className={
                            (errors.startDate || errors.endDate) &&
                            submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {
                            translation.animalWeightHistory.modalExportReport
                              .periodDate
                          }
                          *
                        </label>
                      </Row>
                      <Row type="flex" gutter={8} align="middle">
                        <Col span={8}>
                          <DatePicker
                            value={
                              values.startDate ? values.startDate : undefined
                            }
                            format="DD/MM/YYYY"
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            disabledDate={(current) =>
                              current.isAfter(moment())
                            }
                            onChange={(date) => {
                              setFieldValue("startDate", date);
                            }}
                          />
                        </Col>
                        <Col
                          span={2}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <label style={{ marginLeft: 0 }}>até</label>
                        </Col>
                        <Col span={8}>
                          <DatePicker
                            value={values.endDate ? values.endDate : undefined}
                            format="DD/MM/YYYY"
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            disabledDate={(current) =>
                              values.startDate
                                ? current.isBefore(values.startDate) ||
                                  current.isAfter(moment())
                                : current.isAfter(moment())
                            }
                            onChange={(date) => {
                              setFieldValue("endDate", date);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={6}>
                      <Row>
                        <label
                          className={
                            errors.gender && submitCount > 0 ? "error" : ""
                          }
                        >
                          {
                            translation.animalWeightHistory.modalExportReport
                              .gender
                          }
                          *
                        </label>
                      </Row>
                      <Row>
                        <Select
                          value={values.gender || undefined}
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => setFieldValue("gender", value)}
                        >
                          <Select.Option value="B">Ambos</Select.Option>
                          <Select.Option value="M">Macho</Select.Option>
                          <Select.Option value="F">Fêmea</Select.Option>
                        </Select>
                      </Row>
                    </Col>
                    <Col span={18}>
                      <Row>
                        <label
                          className={
                            (errors.minAge || errors.maxAge) && submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {
                            translation.animalWeightHistory.modalExportReport
                              .periodAge
                          }
                        </label>
                      </Row>
                      <Row type="flex" gutter={8} align="middle">
                        <Col span={8}>
                          <InputNumber
                            name="minAge"
                            value={
                              values.minAge != null ? values.minAge : undefined
                            }
                            placeholder={translation.defaultPlaceholder}
                            min={0}
                            max={9999}
                            onChange={(value) => setFieldValue("minAge", value)}
                          />
                        </Col>
                        <Col
                          span={2}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <label style={{ marginLeft: 0 }}>até</label>
                        </Col>
                        <Col span={8}>
                          <InputNumber
                            name="maxAge"
                            value={
                              values.maxAge != null ? values.maxAge : undefined
                            }
                            placeholder={translation.defaultPlaceholder}
                            min={values.minAge || 0}
                            max={9999}
                            onChange={(value) => setFieldValue("maxAge", value)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={12}>
                      <Row>
                        <label>
                          {
                            translation.animalWeightHistory.modalExportReport
                              .lotId
                          }
                        </label>
                      </Row>
                      <Row type="flex">
                        <Select
                          name="lotId"
                          mode="multiple"
                          value={values.lotId || undefined}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={
                            translation.defaultMultiSelectPlaceholder
                          }
                          onChange={(value) => {
                            setFieldValue("lotId", value);
                            setFieldValue(
                              "lotName",
                              lots
                                .filter((l) => value.includes(l.id))
                                .map((l) => l.name)
                            );
                          }}
                        >
                          {lots.map((c) => (
                            <Select.Option value={c.id} key={c.id}>
                              {c.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <label>
                          {
                            translation.animalWeightHistory.modalExportReport
                              .supplierId
                          }
                        </label>
                      </Row>
                      <Row type="flex">
                        <Select
                          name="supplierId"
                          mode="multiple"
                          value={values.supplierId || undefined}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={
                            translation.defaultMultiSelectPlaceholder
                          }
                          loading={isLoadingDropDownSuppliers}
                          onChange={(value) => {
                            setFieldValue("supplierId", value);
                            setFieldValue(
                              "supplierName",
                              suppliers
                                .filter((l) => value.includes(l.id))
                                .map((l) => l.name)
                            );
                          }}
                        >
                          {suppliers.map((c) => (
                            <Select.Option value={c.id} key={c.id}>
                              {c.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={8}>
                      <Row>
                        <label
                          className={
                            errors.status && submitCount > 0 ? "error" : ""
                          }
                        >
                          {
                            translation.animalWeightHistory.modalExportReport
                              .status
                          }
                          *
                        </label>
                      </Row>
                      <Row>
                        <Select
                          value={values.status || undefined}
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => setFieldValue("status", value)}
                        >
                          <Select.Option value="T">Todos</Select.Option>
                          <Select.Option value="A">Ativos</Select.Option>
                          <Select.Option value="D">Mortos</Select.Option>
                          <Select.Option value="S">Vendidos</Select.Option>
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={24}>
                      <Checkbox
                        checked={values.listAnimalsWithoutWeight || false}
                        onChange={(e) =>
                          setFieldValue(
                            "listAnimalsWithoutWeight",
                            e.target.checked
                          )
                        }
                      >
                        {
                          translation.animalWeightHistory.modalExportReport
                            .listAnimalsWithoutWeight
                        }
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={24}>
                      <Checkbox
                        checked={values.showProjectedWeight || false}
                        onChange={(e) =>
                          setFieldValue("showProjectedWeight", e.target.checked)
                        }
                      >
                        {
                          translation.animalWeightHistory.modalExportReport
                            .showProjectedWeight
                        }
                      </Checkbox>
                    </Col>
                  </Row>
                </div>
                {/* Footer */}
                <Row type="flex">
                  <Col span={24}>
                    <Row type="flex" justify="end" align="middle" gutter={8}>
                      <Col>
                        <ButtonStandard
                          type="button"
                          buttonType="type4"
                          size="s"
                          onClick={closeModal}
                        >
                          {translation.buttons.cancel}
                        </ButtonStandard>
                      </Col>
                      <Col>
                        <ButtonStandard
                          type="submit"
                          buttonType="type1"
                          size="s"
                        >
                          {translation.buttons.generate}
                        </ButtonStandard>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            )}
          />
        </Spin>
      </Container>
    </Modal>
  );
};

export default ReportAnimalWeightHistoryModal;
