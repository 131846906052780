import styled from "styled-components";

export const FormModalReportFeedingSheet = styled.form`
  .ant-row > label {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;

    &.error {
      color: #d44c4c;
    }
  }

  .error {
    color: #d44c4c;
  }
`;
