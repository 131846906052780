export const Types = {
  RESET_DATA: "lot/RESET_DATA",
  GET_ALL_LOTS_PRODUCTION_DROPDOWN: "lot/GET_ALL_LOTS_PRODUCTION_DROPDOWN",
  GET_ALL_LOTS_PRODUCTION_DROPDOWN_SUCCESS:
    "lot/GET_ALL_LOTS_PRODUCTION_DROPDOWN_SUCCESS",
  GET_ALL_LOTS_PRODUCTION_DROPDOWN_ERROR:
    "lot/GET_ALL_LOTS_PRODUCTION_DROPDOWN_ERROR",
  GET_ALL_LOTS_PRODUCTION: "lot/GET_ALL_LOTS_PRODUCTION",
  GET_ALL_LOTS_PRODUCTION_SUCCESS: "lot/GET_ALL_LOTS_PRODUCTION_SUCCESS",
  GET_ALL_LOTS_PRODUCTION_ERROR: "lot/GET_ALL_LOTS_PRODUCTION_ERROR",
  GET_LOT: "lot/GET_LOT",
  GET_LOT_SUCCESS: "lot/GET_LOT_SUCCESS",
  GET_LOT_ERROR: "lot/GET_LOT_ERROR",
  ADD_NEW_LOT_RECEIPT: "lot/ADD_NEW_LOT_RECEIPT",
  ADD_NEW_LOT_RECEIPT_ITEM: "lot/ADD_NEW_LOT_RECEIPT_ITEM",
  REMOVE_LOT_RECEIPT_ITEM: "lot/REMOVE_LOT_RECEIPT_ITEM",
  STORE_NEW_LOT_RECEIPT: "lot/STORE_NEW_LOT_RECEIPT",
  STORE_NEW_LOT_RECEIPT_SUCCESS: "lot/STORE_NEW_LOT_RECEIPT_SUCCESS",
  STORE_NEW_LOT_RECEIPT_ERROR: "lot/STORE_NEW_LOT_RECEIPT_ERROR",
  CANCEL_LOT_RECEIPT: "lot/CANCEL_LOT_RECEIPT",
  SHOW_DRAWER_LOT_ANIMAL_RECEIPT: "lot/SHOW_DRAWER_LOT_ANIMAL_RECEIPT",
  HIDE_DRAWER_LOT_ANIMAL_RECEIPT: "lot/HIDE_DRAWER_LOT_ANIMAL_RECEIPT",
  SHOW_DRAWER_LOT_ITEM_RECEIPT: "lot/SHOW_DRAWER_LOT_ITEM_RECEIPT",
  HIDE_DRAWER_LOT_ITEM_RECEIPT: "lot/HIDE_DRAWER_LOT_ITEM_RECEIPT",
  GET_LOT_ITEM: "lot/GET_LOT_ITEM",
  UPDATE_LOT_ITEM: "lot/UPDATE_LOT_ITEM",
  SHOW_DRAWER_LOT_PRODUCTION: "lot/SHOW_DRAWER_LOT_PRODUCTION",
  HIDE_DRAWER_LOT_PRODUCTION: "lot/HIDE_DRAWER_LOT_PRODUCTION",
  SAVE_LOT_PRODUCTION: "lot/SAVE_LOT_PRODUCTION",
  SAVE_LOT_PRODUCTION_SUCCESS: "lot/SAVE_LOT_PRODUCTION_SUCCESS",
  SAVE_LOT_PRODUCTION_AND_CONTINUE_SUCCESS:
    "lot/SAVE_LOT_PRODUCTION_AND_CONTINUE_SUCCESS",
  SAVE_LOT_PRODUCTION_ERROR: "lot/SAVE_LOT_PRODUCTION_ERROR",
  ADD_ANIMALS_TO_LOT_PRODUCTION: "lot/ADD_ANIMALS_TO_LOT_PRODUCTION",
  ADD_ANIMALS_TO_LOT_PRODUCTION_SUCCESS:
    "lot/ADD_ANIMALS_TO_LOT_PRODUCTION_SUCCESS",
  ADD_ANIMALS_TO_LOT_PRODUCTION_ERROR:
    "lot/ADD_ANIMALS_TO_LOT_PRODUCTION_ERROR",
  SHOW_DRAWER_LOT_DISMEMBER: "lot/SHOW_DRAWER_LOT_DISMEMBER",
  HIDE_DRAWER_LOT_DISMEMBER: "lot/HIDE_DRAWER_LOT_DISMEMBER",
  DISMEMBER_LOT: "lot/DISMEMBER_LOT",
  DISMEMBER_LOT_SUCCESS: "lot/DISMEMBER_LOT_SUCCESS",
  DISMEMBER_LOT_ERROR: "lot/DISMEMBER_LOT_ERROR",
  UPDATE_DISMEMBER_LOT: "lot/UPDATE_DISMEMBER_LOT",
  COMPARE_LOTS: "lot/COMPARE_LOTS",
  REFRESH_TABLE: "lot/REFRESH_TABLE",
  SHOW_OR_HIDE_MODAL_DELETE: "lot/SHOW_OR_HIDE_MODAL_DELETE",
  DELETE_LOT_PRODUCTION: "lot/DELETE_LOT_PRODUCTION",
  DELETE_LOT_PRODUCTION_SUCCESS: "lot/DELETE_LOT_PRODUCTION_SUCCESS",
  DELETE_LOT_PRODUCTION_ERROR: "lot/DELETE_LOT_PRODUCTION_ERROR",
  SHOW_OR_HIDE_MODAL_ACTIVATE: "lot/SHOW_OR_HIDE_MODAL_ACTIVATE",
  ACTIVATE_LOT_PRODUCTION: "lot/ACTIVATE_LOT_PRODUCTION",
  ACTIVATE_LOT_PRODUCTION_SUCCESS: "lot/ACTIVATE_LOT_PRODUCTION_SUCCESS",
  ACTIVATE_LOT_PRODUCTION_ERROR: "lot/ACTIVATE_LOT_PRODUCTION_ERROR",
  INACTIVATE_LOT_PRODUCTION: "lot/INACTIVATE_LOT_PRODUCTION",
  INACTIVATE_LOT_PRODUCTION_SUCCESS: "lot/INACTIVATE_LOT_PRODUCTION_SUCCESS",
  INACTIVATE_LOT_PRODUCTION_ERROR: "lot/INACTIVATE_LOT_PRODUCTION_ERROR",
  SHOW_ANIMAL_IN_CHART_FROM_ANIMAL_DETAILS:
    "lot/SHOW_ANIMAL_IN_CHART_FROM_ANIMAL_DETAILS",
  RESET_PAGINATION_LOT_PRODUCTION: "lot/RESET_PAGINATION_LOT_PRODUCTION",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  getLotsProductionDropDown: (groupId, farmId) => ({
    type: Types.GET_ALL_LOTS_PRODUCTION_DROPDOWN,
    payload: {
      groupId,
      farmId,
    },
  }),
  getLotsProductionDropDownSuccess: (data) => ({
    type: Types.GET_ALL_LOTS_PRODUCTION_DROPDOWN_SUCCESS,
    payload: {
      data,
    },
  }),
  getLotsProductionDropDownError: (error) => ({
    type: Types.GET_ALL_LOTS_PRODUCTION_DROPDOWN_ERROR,
    payload: {
      error,
    },
  }),
  indexLotsProduction: (
    groupId,
    farmId,
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids = null,
    tableProdSorters = null,
    tableProdFilters = null
  ) => ({
    type: Types.GET_ALL_LOTS_PRODUCTION,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      ids,
      tableProdSorters,
      tableProdFilters,
    },
  }),
  indexLotsProductionSuccess: (data) => ({
    type: Types.GET_ALL_LOTS_PRODUCTION_SUCCESS,
    payload: {
      data,
    },
  }),
  indexLotsProductionError: (error) => ({
    type: Types.GET_ALL_LOTS_PRODUCTION_ERROR,
    payload: {
      error,
    },
  }),
  showLot: (groupId, farmId, id) => ({
    type: Types.GET_LOT,
    payload: { groupId, farmId, id },
  }),
  showLotSuccess: (data) => ({
    type: Types.GET_LOT_SUCCESS,
    payload: { data },
  }),
  showLotError: (error) => ({
    type: Types.GET_LOT_ERROR,
    payload: { error },
  }),
  addNewLotReceipt: (lotReceipt) => ({
    type: Types.ADD_NEW_LOT_RECEIPT,
    payload: {
      lotReceipt,
    },
  }),
  addNewLotReceiptItem: (lotItem, saveOption) => ({
    type: Types.ADD_NEW_LOT_RECEIPT_ITEM,
    payload: {
      lotItem,
      saveOption,
    },
  }),
  removeLotReceiptItem: (id) => ({
    type: Types.REMOVE_LOT_RECEIPT_ITEM,
    payload: {
      id,
    },
  }),
  storeNewLotReceipt: (
    groupId,
    farmId,
    lotReceipt,
    lotItens,
    history,
    saveOption
  ) => ({
    type: Types.STORE_NEW_LOT_RECEIPT,
    payload: {
      groupId,
      farmId,
      lotReceipt,
      lotItens,
      history,
      saveOption,
    },
  }),
  storeNewLotReceiptSuccess: (data, saveOption) => ({
    type: Types.STORE_NEW_LOT_RECEIPT_SUCCESS,
    payload: {
      data,
      saveOption,
    },
  }),
  storeNewLotReceiptError: (error) => ({
    type: Types.STORE_NEW_LOT_RECEIPT_ERROR,
    payload: {
      error,
    },
  }),
  cancelLotReceipt: () => ({
    type: Types.CANCEL_LOT_RECEIPT,
    payload: {},
  }),
  showLotAnimalsReceipt: () => ({
    type: Types.SHOW_DRAWER_LOT_ANIMAL_RECEIPT,
    payload: {},
  }),
  hideLotAnimalsReceipt: () => ({
    type: Types.HIDE_DRAWER_LOT_ANIMAL_RECEIPT,
    payload: {},
  }),
  showLotItemReceipt: (shouldCreateLotEntry = false) => ({
    type: Types.SHOW_DRAWER_LOT_ITEM_RECEIPT,
    payload: {
      shouldCreateLotEntry,
    },
  }),
  hideLotItemReceipt: () => ({
    type: Types.HIDE_DRAWER_LOT_ITEM_RECEIPT,
    payload: {},
  }),
  getLotItem: (lotItem, shouldCreateLotEntry = false) => ({
    type: Types.GET_LOT_ITEM,
    payload: {
      lotItem,
      shouldCreateLotEntry,
    },
  }),
  updateLotItem: (lotItem) => ({
    type: Types.UPDATE_LOT_ITEM,
    payload: {
      lotItem,
    },
  }),
  showDrawerLotProduction: (id) => ({
    type: Types.SHOW_DRAWER_LOT_PRODUCTION,
    payload: { id },
  }),
  hideDrawerLotProduction: () => ({
    type: Types.HIDE_DRAWER_LOT_PRODUCTION,
    payload: {},
  }),
  saveLotProduction: (
    lotProduction,
    groupId,
    farmId,
    id,
    saveAndContinue,
    history
  ) => ({
    type: Types.SAVE_LOT_PRODUCTION,
    payload: {
      lotProduction,
      groupId,
      farmId,
      id,
      saveAndContinue,
      history,
    },
  }),
  saveLotProductionSuccess: (data) => ({
    type: Types.SAVE_LOT_PRODUCTION_SUCCESS,
    payload: {
      data,
    },
  }),
  saveLotProductionAndContinueSuccess: (data) => ({
    type: Types.SAVE_LOT_PRODUCTION_AND_CONTINUE_SUCCESS,
    payload: {
      data,
    },
  }),
  saveLotProductionError: (error) => ({
    type: Types.SAVE_LOT_PRODUCTION_ERROR,
    payload: {
      error,
    },
  }),
  addAnimalsToLotProduction: (id, groupId, farmId, animals, history) => ({
    type: Types.ADD_ANIMALS_TO_LOT_PRODUCTION,
    payload: {
      id,
      groupId,
      farmId,
      animals,
      history,
    },
  }),
  addAnimalsToLotProductionSuccess: (data) => ({
    type: Types.ADD_ANIMALS_TO_LOT_PRODUCTION_SUCCESS,
    payload: {
      data,
    },
  }),
  addAnimalsToLotProductionError: (error) => ({
    type: Types.ADD_ANIMALS_TO_LOT_PRODUCTION_ERROR,
    payload: {
      error,
    },
  }),
  showDrawerLotDismember: (selectedAnimalsKeys, lotDismember) => ({
    type: Types.SHOW_DRAWER_LOT_DISMEMBER,
    payload: {
      selectedAnimalsKeys,
      lotDismember,
    },
  }),
  hideDrawerLotDismember: () => ({
    type: Types.HIDE_DRAWER_LOT_DISMEMBER,
    payload: {},
  }),
  dismemberLot: (id, groupId, farmId, dismemberLot, history) => ({
    type: Types.DISMEMBER_LOT,
    payload: {
      id,
      groupId,
      farmId,
      dismemberLot,
      history,
    },
  }),
  dismemberLotSuccess: (data) => ({
    type: Types.DISMEMBER_LOT_SUCCESS,
    payload: {
      data,
    },
  }),
  dismemberLotError: (error) => ({
    type: Types.DISMEMBER_LOT_ERROR,
    payload: {
      error,
    },
  }),
  updateLotDismember: (lotDismember, hideDrawer) => ({
    type: Types.UPDATE_DISMEMBER_LOT,
    payload: {
      lotDismember,
      hideDrawer,
    },
  }),
  compareLots: (lots) => ({
    type: Types.COMPARE_LOTS,
    payload: {
      lots,
    },
  }),
  refreshTable: (refresh) => ({
    type: Types.REFRESH_TABLE,
    payload: {
      refresh,
    },
  }),
  showOrHideModalDelete: (lot) => ({
    type: Types.SHOW_OR_HIDE_MODAL_DELETE,
    payload: {
      lot,
    },
  }),
  deleteLotProduction: (groupId, farmId, lotId) => ({
    type: Types.DELETE_LOT_PRODUCTION,
    payload: {
      groupId,
      farmId,
      lotId,
    },
  }),
  deleteLotProductionSuccess: () => ({
    type: Types.DELETE_LOT_PRODUCTION_SUCCESS,
    payload: {},
  }),
  deleteLotProductionError: (error) => ({
    type: Types.DELETE_LOT_PRODUCTION_ERROR,
    payload: {
      error,
    },
  }),
  showOrHideModalActivate: (lot) => ({
    type: Types.SHOW_OR_HIDE_MODAL_ACTIVATE,
    payload: {
      lot,
    },
  }),
  activateLotProduction: (groupId, farmId, lotId) => ({
    type: Types.ACTIVATE_LOT_PRODUCTION,
    payload: {
      groupId,
      farmId,
      lotId,
    },
  }),
  activateLotProductionSuccess: () => ({
    type: Types.ACTIVATE_LOT_PRODUCTION_SUCCESS,
    payload: {},
  }),
  activateLotProductionError: (error) => ({
    type: Types.ACTIVATE_LOT_PRODUCTION_ERROR,
    payload: {
      error,
    },
  }),
  inactivateLotProduction: (groupId, farmId, lotId, lot) => ({
    type: Types.INACTIVATE_LOT_PRODUCTION,
    payload: {
      groupId,
      farmId,
      lotId,
      lot,
    },
  }),
  inactivateLotProductionSuccess: () => ({
    type: Types.INACTIVATE_LOT_PRODUCTION_SUCCESS,
    payload: {},
  }),
  inactivateLotProductionError: (error) => ({
    type: Types.INACTIVATE_LOT_PRODUCTION_ERROR,
    payload: {
      error,
    },
  }),
  showAnimalInChartFromAnimalDetails: (animal) => ({
    type: Types.SHOW_ANIMAL_IN_CHART_FROM_ANIMAL_DETAILS,
    payload: { animal },
  }),
  resetPaginationLotProduction: () => ({
    type: Types.RESET_PAGINATION_LOT_PRODUCTION,
    payload: {},
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  isLoadingRequest: false,
  dataLotProduction: null,
  isLotListDrowpDownLoading: false,
  lotListDrowpDown: [],
  paginationLotProduction: {
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    tableProdSorters: null,
    tableProdFilters: null,
  },
  dataLot: null,
  lotIdEdit: null,
  error: null,
  newLotReceipt: localStorage.getItem("@Bovexo:newLotReceipt")
    ? JSON.parse(localStorage.getItem("@Bovexo:newLotReceipt"))
    : {},
  lotItens: localStorage.getItem("@Bovexo:lotItens")
    ? JSON.parse(localStorage.getItem("@Bovexo:lotItens"))
    : [],
  lotItem: {
    id: null,
    breedId: null,
    breedName: null,
    gender: null,
    birthday: null,
    estimatedWeight: "",
    amount: "",
    purchaseCost: 0,
    numbersArray: [],
    numbers: "",
    numbersInserted: 0,
  },
  drawerLotAnimalsReceiptVisible: false,
  drawerLotItemReceiptVisible: false,
  drawerLotProduction: false,
  drawerLotDismemberVisible: false,
  lotDismember: {},
  lotDismemberDestinationLots: [],
  compareLots: localStorage.getItem("@Bovexo:compareLots")
    ? JSON.parse(localStorage.getItem("@Bovexo:compareLots"))
    : null,
  refresh: false,
  refreshListProductionTable: false,
  showModalDeleteLot: false,
  showModalActivateLot: false,
  animalSelectedFromAnimalDetails: null,
};

export default function lot(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_LOTS_PRODUCTION_DROPDOWN:
      return {
        ...state,
        isLotListDrowpDownLoading: true,
      };
    case Types.GET_ALL_LOTS_PRODUCTION_DROPDOWN_SUCCESS:
      const { data: lotListDrowpDown } = action.payload;
      return {
        ...state,
        isLotListDrowpDownLoading: false,
        lotListDrowpDown: lotListDrowpDown,
      };
    case Types.GET_ALL_LOTS_PRODUCTION_DROPDOWN_ERROR:
      return {
        ...state,
        isLotListDrowpDownLoading: false,
        lotListDrowpDown: [],
        error: action.payload.error,
      };

    case Types.GET_ALL_LOTS_PRODUCTION:
      const {
        page,
        sorter,
        filters,
        size,
        tableProdSorters,
        tableProdFilters,
      } = action.payload;
      return {
        ...state,
        paginationLotProduction: {
          page,
          sorter,
          filters,
          size,
          tableProdSorters,
          tableProdFilters,
        },
        isLoading: true,
      };
    case Types.GET_ALL_LOTS_PRODUCTION_SUCCESS:
      const { data: lots } = action.payload;
      return { ...state, isLoading: false, dataLotProduction: lots };
    case Types.GET_ALL_LOTS_PRODUCTION_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        dataLotProduction: null,
        error,
      };
    case Types.GET_LOT:
      return { ...state, isLoading: true };
    case Types.GET_LOT_SUCCESS:
      return { ...state, isLoading: false, dataLot: action.payload.data };
    case Types.GET_LOT_ERROR:
      return {
        ...state,
        isLoading: false,
        dataLot: null,
        error: action.payload.error,
      };
    case Types.ADD_NEW_LOT_RECEIPT:
      const { lotReceipt } = action.payload;
      localStorage.setItem("@Bovexo:newLotReceipt", JSON.stringify(lotReceipt));
      return { ...state, newLotReceipt: lotReceipt };
    case Types.ADD_NEW_LOT_RECEIPT_ITEM:
      const { lotItem, saveOption } = action.payload;
      return {
        ...state,
        lotItens: [...state.lotItens, lotItem],
        drawerLotItemReceiptVisible: saveOption,
        lotItem: {
          id: null,
          breedId: null,
          breedName: null,
          gender: null,
          birthday: null,
          animalFarmFunction: "fatten",
          estimatedWeight: "",
          amount: "",
          purchaseCost: "",
          numbersArray: [],
          numbers: null,
          numbersInserted: 0,
        },
      };
    case Types.REMOVE_LOT_RECEIPT_ITEM:
      const { id } = action.payload;
      return {
        ...state,
        lotItens: state.lotItens.filter((lot) => lot.id !== id),
      };
    case Types.STORE_NEW_LOT_RECEIPT:
      return { ...state, isLoading: true };
    case Types.STORE_NEW_LOT_RECEIPT_SUCCESS:
      return {
        ...state,
        newLotReceipt: {},
        lotItens: [],
        error: null,
        isLoading: false,
        drawerLotAnimalsReceiptVisible: action.payload.saveOption,
        drawerLotItemReceiptVisible: false,
      };
    case Types.STORE_NEW_LOT_RECEIPT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.CANCEL_LOT_RECEIPT:
      return {
        ...state,
        newLotReceipt: {},
        lotItens: [],
        drawerLotAnimalsReceiptVisible: false,
      };
    case Types.GET_LOT_ITEM:
      return {
        ...state,
        lotItem: {
          ...action.payload.lotItem,
          shouldCreateLotEntry: action.payload.shouldCreateLotEntry,
        },
        drawerLotItemReceiptVisible: true,
      };
    case Types.UPDATE_LOT_ITEM:
      const updatedLotItem = state.lotItens.map((item) => {
        if (item.id === action.payload.lotItem.id) {
          item = action.payload.lotItem;
        }
        return item;
      });
      return {
        ...state,
        lotItens: updatedLotItem,
        lotItem: {
          id: null,
          breedId: null,
          breedName: null,
          gender: null,
          birthday: null,
          animalFarmFunction: "fatten",
          estimatedWeight: "",
          amount: "",
          shouldCreateLotEntry: false,
          purchaseCost: "",
          prefixIdentification: null,
          lotDestinationId: null,
          numbersArray: [],
          numbers: "",
          numbersInserted: 0,
        },
        drawerLotItemReceiptVisible: false,
      };
    case Types.SHOW_DRAWER_LOT_ANIMAL_RECEIPT:
      return {
        ...state,
        drawerLotAnimalsReceiptVisible: true,
      };
    case Types.HIDE_DRAWER_LOT_ANIMAL_RECEIPT:
      return {
        ...state,
        drawerLotAnimalsReceiptVisible: false,
        lotItens: [],
      };
    case Types.SHOW_DRAWER_LOT_ITEM_RECEIPT:
      return {
        ...state,
        lotItem: {
          id: null,
          breedId: null,
          breedName: null,
          gender: null,
          birthday: null,
          animalFarmFunction: "fatten",
          estimatedWeight: "",
          amount: "",
          shouldCreateLotEntry: action.payload.shouldCreateLotEntry,
          purchaseCost: "",
          prefixIdentification: null,
          lotDestinationId: null,
          numbersArray: [],
          numbers: null,
          numbersInserted: 0,
        },
        drawerLotItemReceiptVisible: true,
      };
    case Types.HIDE_DRAWER_LOT_ITEM_RECEIPT:
      return {
        ...state,
        lotItem: {
          id: null,
          breedId: null,
          breedName: null,
          gender: null,
          birthday: null,
          animalFarmFunction: "fatten",
          estimatedWeight: "",
          amount: "",
          shouldCreateLotEntry: false,
          purchaseCost: "",
          prefixIdentification: null,
          lotDestinationId: null,
          numbersArray: [],
          numbers: null,
          numbersInserted: 0,
        },
        drawerLotItemReceiptVisible: false,
      };
    case Types.SHOW_DRAWER_LOT_PRODUCTION:
      return {
        ...state,
        lotIdEdit: action.payload.id,
        drawerLotProduction: true,
      };
    case Types.HIDE_DRAWER_LOT_PRODUCTION:
      return {
        ...state,
        dataLot: null,
        lotIdEdit: null,
        drawerLotProduction: false,
      };
    case Types.SAVE_LOT_PRODUCTION:
      return {
        ...state,
        isLoadingRequest: true,
      };
    case Types.SAVE_LOT_PRODUCTION_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        error: null,
        dataLot: null,
        drawerLotProduction: false,
        refreshListProductionTable: true,
      };
    case Types.SAVE_LOT_PRODUCTION_AND_CONTINUE_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        error: null,
        dataLot: null,
        drawerLotProduction: true,
        refreshListProductionTable: true,
      };
    case Types.SAVE_LOT_PRODUCTION_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoadingRequest: false,
      };
    case Types.ADD_ANIMALS_TO_LOT_PRODUCTION:
      return {
        ...state,
        isLoading: true,
      };
    case Types.ADD_ANIMALS_TO_LOT_PRODUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case Types.ADD_ANIMALS_TO_LOT_PRODUCTION_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case Types.HIDE_DRAWER_LOT_DISMEMBER:
      return {
        ...state,
        drawerLotDismemberVisible: false,
        refresh: true,
      };
    case Types.SHOW_DRAWER_LOT_DISMEMBER:
      return {
        ...state,
        lotDismemberSelectedAnimalsKeys: action.payload.selectedAnimalsKeys,
        lotDismember: action.payload.lotDismember,
        lotDismemberDestinationLots:
          action.payload.lotDismember?.destinationLots || [],
        drawerLotDismemberVisible: true,
        refresh: false,
      };
    case Types.DISMEMBER_LOT:
      return {
        ...state,
        isLoading: true,
      };
    case Types.DISMEMBER_LOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        lotDismember: null,
        lotDismemberSelectedAnimalsKeys: [],
      };
    case Types.DISMEMBER_LOT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case Types.UPDATE_DISMEMBER_LOT:
      if (action.payload.hideDrawer) {
        return {
          ...state,
          lotDismember: action.payload.lotDismember,
          lotDismemberDestinationLots:
            action.payload.lotDismember?.destinationLots || [],
          drawerLotDismemberVisible: false,
          refresh: true,
        };
      } else {
        return {
          ...state,
          lotDismember: action.payload.lotDismember,
          lotDismemberDestinationLots:
            action.payload.lotDismember?.destinationLots || [],
        };
      }
    case Types.COMPARE_LOTS:
      localStorage.setItem(
        "@Bovexo:compareLots",
        JSON.stringify(action.payload.lots)
      );
      return {
        ...state,
        compareLots: action.payload.lots,
      };
    case Types.REFRESH_TABLE:
      return {
        ...state,
        refreshListProductionTable: action.payload.refresh,
      };
    case Types.SHOW_OR_HIDE_MODAL_DELETE:
      return {
        ...state,
        showModalDeleteLot: !state.showModalDeleteLot,
        dataLot: action.payload.lot,
      };
    case Types.DELETE_LOT_PRODUCTION:
      return {
        ...state,
        error: null,
        isLoading: true,
        isLoadingRequest: true,
      };
    case Types.DELETE_LOT_PRODUCTION_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        isLoadingRequest: false,
        showModalDeleteLot: false,
        dataLot: null,
        refreshListProductionTable: true,
      };
    case Types.DELETE_LOT_PRODUCTION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isLoadingRequest: false,
        refreshListProductionTable: false,
      };
    case Types.SHOW_OR_HIDE_MODAL_ACTIVATE:
      return {
        ...state,
        showModalActivateLot: !state.showModalActivateLot,
        dataLot: action.payload.lot,
      };
    case Types.ACTIVATE_LOT_PRODUCTION:
      return {
        ...state,
        error: null,
        isLoadingRequest: true,
        isLoading: true,
      };
    case Types.ACTIVATE_LOT_PRODUCTION_SUCCESS:
      return {
        ...state,
        error: null,
        isLoadingRequest: false,
        isLoading: false,
        showModalActivateLot: false,
        dataLot: null,
        refreshListProductionTable: true,
      };
    case Types.ACTIVATE_LOT_PRODUCTION_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoadingRequest: false,
        isLoading: false,
        refreshListProductionTable: false,
      };
    case Types.INACTIVATE_LOT_PRODUCTION:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case Types.INACTIVATE_LOT_PRODUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        refreshListProductionTable: true,
      };
    case Types.INACTIVATE_LOT_PRODUCTION_ERROR:
      return {
        ...state,
        isLoading: false,
        refreshListProductionTable: false,
      };
    case Types.SHOW_ANIMAL_IN_CHART_FROM_ANIMAL_DETAILS:
      return {
        ...state,
        animalSelectedFromAnimalDetails: action.payload.animal,
      };
    case Types.RESET_PAGINATION_LOT_PRODUCTION:
      return {
        ...state,
        paginationLotProduction: INITIAL_STATE.paginationLotProduction,
      };
    default:
      return state;
  }
}
