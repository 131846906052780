import axios from "axios";
import moment from "moment";

export const validateDietStrategyValidation = (dietStrategy) => {
  let result = true;
  const dietPeriods = dietStrategy.dietPeriods;
  let maxEndDate = null;

  dietPeriods.forEach((dp) => {
    if (maxEndDate === null) {
      maxEndDate = moment(dp.endDate);
    } else if (moment(dp.endDate).isAfter(maxEndDate, "days")) {
      maxEndDate = moment(dp.endDate);
    }
  });
  if (moment().isAfter(maxEndDate, "days")) {
    result = true;
  } else {
    result = false;
  }
  return result;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  if (phone !== null) {
    const size = phone.match(/\d/g)?.length || 0;
    if (size >= 10 && size <= 11) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const validateCPF = (strCPF) => {
  let Soma = 0;
  let Resto;

  let cpfOnlyNumbers = getOnlyNumber(strCPF);

  if (
    !cpfOnlyNumbers ||
    cpfOnlyNumbers.length !== 11 ||
    cpfOnlyNumbers === "00000000000" ||
    cpfOnlyNumbers === "11111111111" ||
    cpfOnlyNumbers === "22222222222" ||
    cpfOnlyNumbers === "33333333333" ||
    cpfOnlyNumbers === "44444444444" ||
    cpfOnlyNumbers === "55555555555" ||
    cpfOnlyNumbers === "66666666666" ||
    cpfOnlyNumbers === "77777777777" ||
    cpfOnlyNumbers === "88888888888" ||
    cpfOnlyNumbers === "99999999999"
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    Soma = Soma + parseInt(cpfOnlyNumbers.substring(i - 1, i)) * (11 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(cpfOnlyNumbers.substring(9, 10))) {
    return false;
  }

  Soma = 0;

  for (let i = 1; i <= 10; i++) {
    Soma = Soma + parseInt(cpfOnlyNumbers.substring(i - 1, i)) * (12 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(cpfOnlyNumbers.substring(10, 11))) {
    return false;
  }
  return true;
};

export const validateCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (
    !cnpj ||
    cnpj.length !== 14 ||
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  ) {
    return false;
  }
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  // eslint-disable-next-line
  if (resultado != digitos.charAt(0)) {
    return false;
  }
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  // eslint-disable-next-line
  if (resultado != digitos.charAt(1)) {
    return false;
  }
  return true;
};

export const cpfMask = (value) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const phoneMask = (value) => {
  if (value != null) {
    var r = value.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  } else {
    return null;
  }
};

export const numberMask = (
  value,
  isCurrency = false,
  currency = "BRL",
  language = "pt-br",
  maxFractionDigits = 2,
  minFractionDigits = 2
) => {
  if (value) {
    if (isCurrency) {
      return value.toLocaleString(language === null ? "pt-br" : language, {
        maximumFractionDigits:
          maxFractionDigits != null ? maxFractionDigits : 2,
        style: "currency",
        currency: currency === null ? "BRL" : currency,
      });
    } else {
      return value.toLocaleString(language === null ? "pt-br" : language, {
        maximumFractionDigits:
          maxFractionDigits != null ? maxFractionDigits : 2,
        minimumFractionDigits:
          minFractionDigits != null ? minFractionDigits : 2,
      });
    }
  } else {
    if (isCurrency) {
      return "0".toLocaleString(language === null ? "pt-br" : language, {
        maximumFractionDigits:
          maxFractionDigits != null ? maxFractionDigits : 2,
        style: "currency",
        currency: currency === null ? "BRL" : currency,
      });
    } else {
      return "0".toLocaleString(language === null ? "pt-br" : language, {
        maximumFractionDigits:
          maxFractionDigits != null ? maxFractionDigits : 2,
        minimumFractionDigits:
          minFractionDigits != null ? minFractionDigits : 2,
      });
    }
  }
};

export const cnpjMask = (value) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "") //Remove tudo o que não é dígito
    .replace(/^(\d{2})(\d)/, "$1.$2") //Coloca ponto entre o segundo e o terceiro dígitos
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    .replace(/\.(\d{3})(\d)/, ".$1/$2") //Coloca uma barra entre o oitavo e o nono dígitos
    .replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
};

export const getCep = (cep) => {
  let cepOnlyDigits = cep.replace(/\D/g, "");
  return axios.get(`https://viacep.com.br/ws/${cepOnlyDigits}/json/`);
};

export function getTwoDecimalDigits(number, decimalDigits = 2) {
  if (number === 0 || isNaN(number)) {
    return Number.parseFloat("0");
  } else if (number != null) {
    const re = new RegExp(`^-?\\d+(?:.\\d{0,${decimalDigits}})?`);
    let stringNumber = number.toString().match(re)[0];
    return Number.parseFloat(stringNumber);
  } else {
    return Number.parseFloat("0");
  }
}

export function getOnlyNumber(value) {
  if (value != null) {
    return value.replace(/\D/g, "");
  } else {
    return "";
  }
}
