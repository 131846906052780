import React from "react";
import image from "./report_icon.png";
import imageGray from "./report_icon_gray.png";

// import { Container } from './styles',

function ReportIcon() {
  return <img src={image} alt="Menu report icon" />;
}

function ReportIconGray() {
  return <img src={imageGray} alt="Menu report icon" />;
}

export { ReportIcon, ReportIconGray };
