import { Divider, Icon, notification } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  MENU_BOITEL,
  MENU_CONFINEMENT,
  MENU_GROWTH,
  MENU_RECREATE_FATTEN_EXTENSIVE,
} from "../../../contexts/menuNavigationContext/menus";
import useModuleContext from "../../../hooks/useModuleContext";
import usePicketSupplementSupplyContext from "../../../hooks/usePicketSupplementSupplyContext";

/* Actions from ducks */
import { Creators as AppActions } from "../../../store/ducks/app";
import { Creators as AnimalActions } from "../../../store/ducks/animal";
import { Creators as LotActions } from "../../../store/ducks/lot";
import { Creators as PicketActions } from "../../../store/ducks/picket";
import { Creators as ProfitCenterActions } from "../../../store/ducks/profitCenter";
import { Creators as UserActions } from "../../../store/ducks/user";

import { NotificationBadge } from "../../admin/topbar/styles";
import NotificationIcon from "../icons/notification";
import WeatherIcon from "../icons/weather";
import { MenuNavigationButton } from "./components/button";
import {
  MenuNavigationContainer,
  SearchDropDownMenu,
  SearchDropDownSubMenu,
} from "./styles";
import PlusAddMenuIcon from "../../admin/topbar/icons/plus";
import AllowedComponentTo from "../allowedComponentTo";

import { getListOfProfitCenterIndex } from "../../../services/profitCenterService";
import { getTypologyItemIndex } from "../../../services/typologyItemService";
import { downloadBovManagerService } from "../../../services/generalParameterService";
import FavoriteReportIcon from "../icons/favoriteReport";
import { useReportContext } from "../../../hooks/useReportContext";

// import { Container } from './styles';

const PlusMenuOptions = ({
  openMenuPlus,
  user,
  renderPlusMenuInputSupplier,
  openDrawer,
  openModal,
  isDownloadBovManager,
  downloadBovManager,
}) => {
  const { translation } = useSelector((state) => state.app);
  const { isAllowedFunctions } = useModuleContext();
  const { openDrawer: openPicketSupplementSupplyDrawer } =
    usePicketSupplementSupplyContext();

  return (
    <SearchDropDownMenu className={openMenuPlus ? "drop-visible" : ""}>
      {user.isInputSupplier ? (
        renderPlusMenuInputSupplier()
      ) : (
        <>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_PROFIT_CENTER"])
                ? "download disable"
                : ""
            }
          >
            <button
              onClick={() => openDrawer("profitCenter")}
              disabled={!isAllowedFunctions(["PLUS_MENU_PROFIT_CENTER"])}
            >
              {translation.topbar.add.submenus.profit_center}
            </button>
          </li>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_PICKET"])
                ? "download disable"
                : ""
            }
          >
            <button
              onClick={() => openDrawer("picket")}
              disabled={!isAllowedFunctions(["PLUS_MENU_PICKET"])}
            >
              {translation.topbar.add.submenus.picket}
            </button>
          </li>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_PICKET"])
                ? "download disable"
                : ""
            }
          >
            <button onClick={() => openPicketSupplementSupplyDrawer()}>
              {translation.topbar.add.submenus.picketSupplementSupply}
            </button>
          </li>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_ANIMAL"])
                ? "download disable"
                : ""
            }
          >
            <button
              onClick={() => openDrawer("animal")}
              disabled={!isAllowedFunctions(["PLUS_MENU_ANIMAL"])}
            >
              {translation.topbar.add.submenus.animal}
            </button>
          </li>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_LOT"]) ? "download disable" : ""
            }
          >
            <button
              onClick={() => openDrawer("lotProduction")}
              disabled={!isAllowedFunctions(["PLUS_MENU_LOT"])}
            >
              {translation.topbar.add.submenus.lotProduction}
            </button>
          </li>
          <AllowedComponentTo roles={[]} hide>
            <li
              className={
                !isAllowedFunctions(["PLUS_MENU_IMPORT_WEIGHT"])
                  ? "download disable"
                  : ""
              }
            >
              <button
                onClick={() => openModal("animalWeightUpload")}
                disabled={!isAllowedFunctions(["PLUS_MENU_IMPORT_WEIGHT"])}
              >
                {translation.topbar.add.submenus.importAnimalsWeights}
              </button>
            </li>
          </AllowedComponentTo>
          <AllowedComponentTo roles={["Trial"]} reverse hide>
            <li
              className={
                !isAllowedFunctions(["PLUS_MENU_IMPORT_ANIMAL"])
                  ? "download disable"
                  : ""
              }
            >
              <button
                onClick={() => openModal("animalUpload")}
                disabled={!isAllowedFunctions(["PLUS_MENU_IMPORT_ANIMAL"])}
              >
                {translation.topbar.add.submenus.importAnimals}
              </button>
            </li>
          </AllowedComponentTo>
          <AllowedComponentTo roles={["Trial"]} reverse hide>
            <li
              className={
                !isAllowedFunctions(["PLUS_MENU_IMPORT_FINANCIAL_TRANSACTION"])
                  ? "download disable"
                  : ""
              }
            >
              <button
                onClick={() => openModal("financialTransactionUpload")}
                disabled={
                  !isAllowedFunctions([
                    "PLUS_MENU_IMPORT_FINANCIAL_TRANSACTION",
                  ])
                }
              >
                {translation.topbar.add.submenus.importFinancialTransactions}
              </button>
            </li>
          </AllowedComponentTo>
          <AllowedComponentTo roles={["Trial"]} reverse hide>
            <li
              className={
                !isAllowedFunctions(["PLUS_MENU_IMPORT_SUPPLEMENT"])
                  ? "download disable"
                  : ""
              }
            >
              <button onClick={() => openModal("supplementUpload")}>
                {translation.topbar.add.submenus.importSupplements}
              </button>
            </li>
          </AllowedComponentTo>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_DOWNLOAD_BOVMANAGER"])
                ? "download disable"
                : isDownloadBovManager
                ? "download disable"
                : "download"
            }
          >
            <button onClick={downloadBovManager}>
              <span>{translation.topbar.add.submenus.downloadBovManager}</span>
              {isDownloadBovManager && (
                <Icon type="loading" spin style={{ fontSize: 24 }} />
              )}
            </button>
          </li>
          <li>
            <div>
              <span className="title">{translation.topbar.add.title}</span>
              <PlusAddMenuIcon />
            </div>
          </li>
        </>
      )}
    </SearchDropDownMenu>
  );
};

const ReportMenuOptions = ({ openMenuReport }) => {
  const { translation } = useSelector((state) => state.app);
  const [menuReportActive, setMenuReportActive] = useState(null);
  const { favoriteReports, handleRequestReport } = useReportContext();

  const groupReports = useMemo(() => {
    return favoriteReports.reduce((result, item) => {
      const { entity } = item;
      // If the entity key doesn't exist, initialize it with an empty array
      if (!result[entity]) {
        result[entity] = [];
      }
      // Push the name into the corresponding entity array
      result[entity].push({ ...item });
      return result;
    }, {});
  }, [favoriteReports]);

  const getReport = useCallback(
    (report) => {
      handleRequestReport(report);
      setMenuReportActive(null);
    },
    [handleRequestReport]
  );

  const handleActiveSubMenu = useCallback((entity) => {
    setMenuReportActive((prev) => (prev && prev === entity ? null : entity));
  }, []);

  return (
    <SearchDropDownMenu
      id="menuReport"
      className={openMenuReport ? "drop-visible" : ""}
    >
      {Object.entries(groupReports).map(([entity, reports]) => {
        return (
          <li key={`menu_report_${entity}`}>
            <button type="button" onClick={() => handleActiveSubMenu(entity)}>
              {translation.reports.tabs[entity]}
            </button>
            <SearchDropDownSubMenu
              id={`menu_report_${entity}`}
              className={
                menuReportActive && menuReportActive === entity
                  ? "drop-sub-visible"
                  : ""
              }
            >
              {reports.map((report) => {
                const { id, name } = report;
                return (
                  <li key={`menu_report_item_${id}`}>
                    <button type="button" onClick={() => getReport(report)}>
                      {translation.reports.titles[name]}
                    </button>
                  </li>
                );
              })}
            </SearchDropDownSubMenu>
          </li>
        );
      })}
    </SearchDropDownMenu>
  );
};

function MenuNavigation() {
  const [menus, setMenus] = useState([]);
  const [openMenuPlus, setOpenMenuPlus] = useState(false);
  const [openMenuReport, setOpenMenuReport] = useState(false);
  const [isDownloadBovManager, setIsDownloadBovManager] = useState(false);

  const {
    user,
    translation,
    notifications,
    activeLevelOneMenu,
    activeLevelTwoMenu,
    activeLevelThreeMenu,
    activeLevelFourMenu,
    farmSelected: farm,
    farmSelected: { id: farmId },
    groupSelected: { id: groupId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const { module } = useModuleContext();

  const location = useLocation();
  const history = useHistory();

  const menuLevel1Active = useMemo(() => {
    if (menus && activeLevelOneMenu) {
      return menus.find((menu) => menu.id === activeLevelOneMenu);
    }
    return null;
  }, [menus, activeLevelOneMenu]);

  const menuLevel2Active = useMemo(() => {
    if (menus && menuLevel1Active && activeLevelTwoMenu) {
      return menuLevel1Active.children.find(
        (child) => child.id === activeLevelTwoMenu
      );
    }
    return null;
  }, [menus, menuLevel1Active, activeLevelTwoMenu]);

  const menuLevel3Active = useMemo(() => {
    if (
      menus &&
      activeLevelThreeMenu &&
      activeLevelTwoMenu &&
      menuLevel2Active
    ) {
      return menuLevel2Active.children.find(
        (menu) => menu.id === activeLevelThreeMenu
      );
    }
    return null;
  }, [menus, activeLevelThreeMenu, activeLevelTwoMenu, menuLevel2Active]);

  const getFarmProfitCenters = useCallback(async () => {
    if (groupId && farmId)
      try {
        const {
          data: { results: allProfitCenters },
        } = await getListOfProfitCenterIndex({
          groupId,
          farmId,
        });
        const {
          data: { results: profitCenterTypes },
        } = await getTypologyItemIndex({
          groupId,
          typologyName: "Sistema Produtivo",
        });
        const { items } = profitCenterTypes[0];
        if (Object.entries(allProfitCenters).length > 0) {
          if (allProfitCenters.length > 0) {
            allProfitCenters.map((pc) => {
              if (pc.productionSystemId != null) {
                const { name: typeName } = items.find(
                  (t) => t.id === pc.productionSystemId
                );
                pc.typeName = typeName;
              } else {
                pc.typeName = null;
              }
              return pc;
            });
          }
        }
        return allProfitCenters;
      } catch (error) {
        return [];
      }
  }, [farmId, groupId]);

  const filterMenu = useCallback((menuFilter, module) => {
    if (
      menuFilter.availableModules.includes(module) &&
      menuFilter.children.length === 0
    )
      return true;
    return (
      (menuFilter.children = menuFilter.children.filter((children) =>
        filterMenu(children, module)
      )).length > 0
    );
  }, []);

  const openMenuMethod = (menu) => {
    switch (menu) {
      case "plusMenu":
        setOpenMenuPlus((prevState) => !prevState);
        setOpenMenuReport(false);
        break;
      case "reportMenu":
        setOpenMenuReport((prevState) => !prevState);
        setOpenMenuPlus(false);
        break;
      case "plusMenuAnimal":
        setOpenMenuPlus((prevState) => !prevState);
        setOpenMenuReport(false);
        break;
      default:
        break;
    }
  };

  const downloadBovManager = async () => {
    setIsDownloadBovManager(true);
    notification.info({
      message:
        "Aguarde, o BovManager está sendo carregado, e logo será feito o download.",
      duration: 20,
    });
    openMenuMethod("plusMenu");
    try {
      const { data } = await downloadBovManagerService();
      const href = URL.createObjectURL(data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "BovManagerSetup.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      setIsDownloadBovManager(false);

      notification.success({
        message:
          "BovManager carregado! Aguarde até finalizar de abaixar para o seu computador.",
      });
    } catch (error) {
      notification.error({
        message: "Erro ao fazer download do BovManager",
      });
      setIsDownloadBovManager(false);
    }
  };

  const buildMenuThree = useCallback(
    async (module) => {
      if (farm)
        try {
          let allMenus = [];
          let newMenu = [];
          const profitCenters = await getFarmProfitCenters();
          if (profitCenters) {
            if (farm.boitel === true) {
              allMenus.push(MENU_BOITEL);
            }
            profitCenters.forEach((profitCenter) => {
              const { typeName, productionSubSystem } = profitCenter;
              if (typeName === "Engorda" || typeName === "Recria") {
                if (
                  productionSubSystem === "Extensive" ||
                  productionSubSystem === "SemiIntensive"
                ) {
                  allMenus.push(MENU_RECREATE_FATTEN_EXTENSIVE);
                  if (farm.confinement === true) {
                    allMenus.push(MENU_CONFINEMENT);
                  }
                } else {
                  allMenus.push(MENU_CONFINEMENT);
                }
              } else if (typeName === "Cria" && module !== "BASICO") {
                allMenus.push(MENU_GROWTH);
              }
            });
            allMenus = Array.from(new Set([...allMenus]));
            allMenus.sort((a, b) => a.order - b.order);
            for (const menu of allMenus) {
              const filteredMenu = {
                ...menu,
                children: menu.children.filter((menu) =>
                  filterMenu(menu, module)
                ),
              };
              if (filteredMenu.children.length > 0) newMenu.push(filteredMenu);
            }
          }
          setMenus(newMenu);
        } catch (error) {
          console.error(error);
        }
    },
    [farm, filterMenu, getFarmProfitCenters]
  );

  const openModal = (modal) => {
    switch (modal) {
      case "changeGroupAndFarm":
        dispatch(AppActions.showModalGroupAndFarm());
        break;
      case "animalWeightUpload":
        dispatch(AppActions.showModalAnimalWeightUpload());
        break;
      case "animalUpload":
        dispatch(AppActions.showModalAnimalUpload());
        break;
      case "supplementUpload":
        dispatch(AppActions.showModalSupplementUpload());
        break;
      case "financialTransactionUpload":
        dispatch(AppActions.showModalFinancialTransactionUpload());
        break;
      case "contactUs":
        dispatch(AppActions.showModalContactUs());
        break;
      case "systemAbout":
        dispatch(AppActions.showOrHideSystemAboutModal());
        break;
      default:
        break;
    }
  };

  const openDrawer = (drawer) => {
    setOpenMenuPlus(false);
    setOpenMenuReport(false);
    switch (drawer) {
      case "animal":
        dispatch(AnimalActions.showDrawerNewAnimal());
        break;
      case "profitCenter":
        dispatch(ProfitCenterActions.showDrawer("new"));
        break;
      case "picket":
        dispatch(PicketActions.showDrawer("new"));
        break;
      case "newLotAnimalsReceipt":
        dispatch(LotActions.showLotAnimalsReceipt());
        break;
      case "lotProduction":
        dispatch(LotActions.showDrawerLotProduction());
        break;
      case "editProfile":
        dispatch(UserActions.showDrawerEditProfile());
        break;
      case "changePassword":
        dispatch(UserActions.showDrawerChangePassword());
        break;
      case "notifications":
        dispatch(AppActions.showHideDrawerNotifications());
        break;
      case "arrobaPrice":
        dispatch(AppActions.showOrHideDrawerArrobaPrice());
        break;
      case "weather":
        dispatch(AppActions.showOrHideDrawerWeather());
        break;
      default:
        break;
    }
  };

  const renderPlusMenuInputSupplier = () => {
    return (
      <AllowedComponentTo roles={["Trial"]} reverse hide>
        <li>
          <button onClick={() => openModal("supplementUpload")}>
            {translation.topbar.add.submenus.importSupplements}
          </button>
        </li>
      </AllowedComponentTo>
    );
  };

  const handleMenuClick = useCallback(
    (id, level, link = null) => {
      dispatch(AppActions.activeTopMenuNavigation(id, level));

      if (link) {
        history.push(link);
      }
    },
    [dispatch, history]
  );

  const handleRemoveActiveMenu = useCallback(
    (level) => {
      const allMenuNavigationButtons = document.querySelectorAll(
        ".menu-navigation-button"
      );
      const levelButtons = Array.from(allMenuNavigationButtons).filter(
        (buttonElement) => buttonElement.id.includes(String(level))
      );
      levelButtons.forEach((buttonElement) => {
        buttonElement.classList.remove("removed");
        buttonElement.classList.remove("active");
      });
      dispatch(AppActions.inactiveTopMenuNavigation(level));
    },
    [dispatch]
  );

  useEffect(() => {
    if (module) buildMenuThree(module);
  }, [buildMenuThree, module]);

  return !location.pathname.includes("new") &&
    !location.pathname.includes("edit") &&
    menus.length > 0 ? (
    <MenuNavigationContainer>
      <div className="principal-menu-container">
        {activeLevelOneMenu && (
          <button
            className="button-rollback-navigation"
            onClick={() => handleRemoveActiveMenu(1)}
          >
            <Icon type="left" />
          </button>
        )}
        <div className="menu-level-container menu-level1">
          {menus
            .filter((menu) => menu.level === 1)
            .map((menu) => {
              return (
                (menu.children.length > 0 || menu.link !== null) &&
                (activeLevelOneMenu === null ||
                  activeLevelOneMenu === menu.id) && (
                  <MenuNavigationButton
                    id={menu.id}
                    active={activeLevelOneMenu === menu.id}
                    color={menu.color}
                    link={menu.link}
                    level={menu.level}
                    key={`button-${menu.id}`}
                    label={translation.menuNavigation[menu.id]}
                    onClick={handleMenuClick}
                  />
                )
              );
            })}
        </div>
        {activeLevelTwoMenu ? (
          <button
            className="button-rollback-navigation"
            onClick={() => handleRemoveActiveMenu(2)}
          >
            <Icon type="left" />
          </button>
        ) : activeLevelOneMenu ? (
          <Divider className="menu-navigation-divider" type="vertical" />
        ) : null}
        <div className="menu-level-container menu-level2">
          {menuLevel1Active &&
            menuLevel1Active.children
              .filter((menu) => menu.children.length > 0)
              .map((menu) => {
                return (
                  (menu.children.length > 0 || menu.link !== null) &&
                  (activeLevelTwoMenu === null ||
                    activeLevelTwoMenu === menu.id) && (
                    <MenuNavigationButton
                      id={menu.id}
                      active={activeLevelTwoMenu === menu.id}
                      color={menuLevel1Active.color}
                      link={menu.link}
                      level={menu.level}
                      key={`button-${menu.id}`}
                      label={translation.menuNavigation[menu.id]}
                      onClick={handleMenuClick}
                    />
                  )
                );
              })}
        </div>
        {activeLevelTwoMenu ? (
          <Divider className="menu-navigation-divider" type="vertical" />
        ) : null}
        <div className="menu-level-container menu-level3">
          {menuLevel2Active &&
            menuLevel2Active.children.map(
              (menu) =>
                (menu.children.length > 0 || menu.link !== null) && (
                  <MenuNavigationButton
                    id={menu.id}
                    active={activeLevelThreeMenu === menu.id}
                    link={menu.link}
                    color={menuLevel1Active.color}
                    level={menu.level}
                    key={`button-${menu.id}`}
                    label={translation.menuNavigation[menu.id]}
                    onClick={handleMenuClick}
                  />
                )
            )}
        </div>
      </div>
      <div className="sub-menu-container">
        <div className="menu-level-container">
          {menuLevel3Active &&
            menuLevel3Active.children.map((menu) => (
              <MenuNavigationButton
                id={menu.id}
                active={activeLevelFourMenu === menu.id}
                link={menu.link}
                color={menuLevel1Active.color}
                level={menu.level}
                key={`button-${menu.id}`}
                label={translation.menuNavigation[menu.id]}
                onClick={handleMenuClick}
              />
            ))}
        </div>
        <div className="options-buttons">
          <div className="buttons-options">
            <button
              style={{ all: "unset" }}
              onClick={() => openMenuMethod("reportMenu")}
            >
              <FavoriteReportIcon />
            </button>
            <ReportMenuOptions openMenuReport={openMenuReport} />
          </div>
          <div
            className="buttons-options"
            onClick={() => openMenuMethod("plusMenu")}
          >
            <Icon type="plus" />
            <PlusMenuOptions
              openMenuPlus={openMenuPlus}
              user={user}
              renderPlusMenuInputSupplier={renderPlusMenuInputSupplier}
              openDrawer={openDrawer}
              openModal={openModal}
              isDownloadBovManager={isDownloadBovManager}
              downloadBovManager={downloadBovManager}
            />
          </div>
          <div
            className="buttons-options"
            onClick={() => openDrawer("notifications")}
          >
            <NotificationIcon />
            {notifications != null && notifications.content.length > 0 ? (
              <NotificationBadge>
                {notifications.totalElements}
              </NotificationBadge>
            ) : null}
          </div>
          <div
            className="buttons-options"
            onClick={() => openDrawer("arrobaPrice")}
          >
            $@
          </div>
          <div
            className="buttons-options"
            onClick={() => openDrawer("weather")}
          >
            <WeatherIcon />
          </div>
        </div>
      </div>
    </MenuNavigationContainer>
  ) : null;
}

export { MenuNavigation };
