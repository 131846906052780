import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";
import { Creators as ReportActions } from "../../../store/ducks/report";
import * as Yup from "yup";
// Components
import {
  Checkbox,
  Col,
  DatePicker,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import CustomDivider from "../../utils/customDivider";
import {
  Container,
  ExtensionButton,
  ExtensionContainer,
  Title,
} from "./styles";
import ExportPdfIcon from "../../utils/icons/export/pdf";
// Services
import fileReader from "../../../utils/fileReader";
import { getBreedIndexActive } from "../../../services/breedService";
import { findAllFarmOwners } from "../../../services/farmOwnerService";
import { processReportMovementAnimals } from "../../../services/reportService";

const validationSchema = Yup.object().shape({
  onlyFinalStock: Yup.boolean().required(),
  allFarmGroups: Yup.boolean().required(),
  startDatePeriod: Yup.string().when(["onlyFinalStock"], {
    is: (onlyFinalStock) => onlyFinalStock === true,
    then: Yup.string().nullable().notRequired(),
    otherwise: Yup.string().required(),
  }),
  endDatePeriod: Yup.string().when(["onlyFinalStock"], {
    is: (onlyFinalStock) => onlyFinalStock === true,
    then: Yup.string().nullable().notRequired(),
    otherwise: Yup.string().required(),
  }),
});

const ExportReportMovementAnimalsModal = () => {
  // Variables
  const formikRef = useRef();
  const [breeds, setBreeds] = useState([]);
  const [farmOwners, setFarmOwners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Redux Variables
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { listDrowpDown: suppliers } = useSelector((state) => state.supplier);
  const { isModalMovementReportVisible: modalVisible } = useSelector(
    (state) => state.report
  );

  const dispatch = useDispatch();

  // Methods

  const fetchSuppliers = useCallback(async () => {
    dispatch(
      SupplierActions.getDropdownSuppliers(
        groupId,
        farmId,
        "CattleFarmer",
        true
      )
    );
  }, [dispatch, farmId, groupId]);

  const fetchFarmOwners = useCallback(async () => {
    try {
      const {
        data: { results },
      } = await findAllFarmOwners({
        groupId,
        farmId,
      });

      setFarmOwners(results);
    } catch (error) {}
  }, [farmId, groupId]);
  const fetchBreeds = useCallback(async () => {
    const {
      data: { results },
    } = await getBreedIndexActive({
      groupId,
      farmId,
      withoutBreedCurveParam: true,
    });

    setBreeds(results);
  }, [farmId, groupId]);

  function handleClose() {
    formikRef.current.resetForm({
      onlyFinalStock: false,
      allFarmGroups: false,
      startDatePeriod: null,
      endDatePeriod: null,
      farmOwnerId: null,
      supplierId: null,
      breedId: null,
    });
    dispatch(ReportActions.hideModal());
  }

  async function handleSubmit(values) {
    const body = {
      ...values,
      startDatePeriod: !values.startDatePeriod
        ? null
        : moment(values.startDatePeriod).format("YYYY-MM-DD"),
      endDatePeriod: !values.endDatePeriod
        ? null
        : moment(values.endDatePeriod).format("YYYY-MM-DD"),
    };
    setTimeout(() => {
      setIsLoading(false);
      formikRef.current.resetForm({
        onlyFinalStock: false,
        allFarmGroups: false,
        startDatePeriod: null,
        endDatePeriod: null,
        farmOwnerId: null,
        supplierId: null,
        breedId: null,
      });
      dispatch(ReportActions.hideModal());
    }, 100);
    try {
      const { data } = await processReportMovementAnimals({
        groupId,
        farmId,
        body,
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      const dayFormat = moment().format("YYYYMMDD");
      const hourFormat = moment().format("HHmm");
      link.setAttribute(
        "download",
        `Relatorio_Mov_${dayFormat}_${hourFormat}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      notification.success({
        message: translation.successGenerateReport,
      });
    } catch (error) {
      if (error?.response) {
        try {
          const { data } = error.response;
          // Read file
          const file = await fileReader(data);

          if (typeof file === "string") {
            // Parse content and retrieve 'message'
            const result = JSON.parse(file);
            if (result.code === 5035) {
              notification.error({
                message:
                  "Não foram encontrados animais com os filtros informados.",
              });
            } else {
              notification.error({
                message: translation.errorGenerateReport,
              });
            }
          }
        } catch (readError) {
          notification.error({
            message: translation.errorGenerateReport,
          });
        }
      } else {
        notification.error({
          message: translation.errorGenerateReport,
        });
      }
    }
  }

  useEffect(() => {
    if (modalVisible === true) {
      Promise.all([fetchFarmOwners(), fetchBreeds(), fetchSuppliers()]);
    }

    return () => {
      setBreeds([]);
      setFarmOwners([]);
    };
  }, [fetchBreeds, fetchFarmOwners, fetchSuppliers, modalVisible]);

  return (
    <Container
      width={600}
      visible={modalVisible}
      centered
      closable={true}
      footer={null}
      onCancel={handleClose}
    >
      <Spin spinning={isLoading}>
        <Row type="flex">
          <Col span={24}>
            <Title>{translation.movementAnimalReportModalRequest.title}</Title>
          </Col>
        </Row>
        <Formik
          ref={formikRef}
          initialValues={{
            onlyFinalStock: false,
            allFarmGroups: false,
            startDatePeriod: null,
            endDatePeriod: null,
            farmOwnerId: null,
            supplierId: null,
            breedId: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={({
            values,
            errors,
            setFieldValue,
            submitCount,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              {Object.keys(errors).length > 0 && submitCount > 0 && (
                <Row type="flex" justify="center">
                  <label className="error">{translation.error.formError}</label>
                </Row>
              )}
              <Row type="flex" gutter={16}>
                <Col span={12}>
                  <Checkbox
                    checked={values.onlyFinalStock}
                    onChange={(e) =>
                      setFieldValue("onlyFinalStock", e.target.checked)
                    }
                  >
                    {
                      translation.movementAnimalReportModalRequest.form
                        .onlyFinalStock
                    }
                  </Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox
                    disabled={!values.onlyFinalStock}
                    checked={values.allFarmGroups}
                    onChange={(e) =>
                      setFieldValue("allFarmGroups", e.target.checked)
                    }
                  >
                    {
                      translation.movementAnimalReportModalRequest.form
                        .allFarmGroups
                    }
                  </Checkbox>
                </Col>
              </Row>
              <Row type="flex" gutter={16}>
                <Col span={24}>
                  <Row>
                    <label
                      htmlFor="reportDate"
                      className={`${
                        (errors.startDatePeriod || errors.endDatePeriod) &&
                        submitCount > 0
                          ? "error"
                          : ""
                      }`}
                    >
                      {
                        translation.movementAnimalReportModalRequest.form
                          .datePeriod
                      }
                      *
                    </label>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col>
                      <DatePicker
                        disabled={values.onlyFinalStock}
                        name="startDatePeriod"
                        format={"DD/MM/YYYY"}
                        value={values.startDatePeriod ?? undefined}
                        placeholder={translation.defaultPlaceholder}
                        disabledDate={(current) => current.isAfter(moment())}
                        onChange={(date) =>
                          setFieldValue("startDatePeriod", date)
                        }
                      />
                    </Col>
                    <Col>
                      <DatePicker
                        disabled={values.onlyFinalStock}
                        name="endDatePeriod"
                        format={"DD/MM/YYYY"}
                        value={values.endDatePeriod ?? undefined}
                        placeholder={translation.defaultPlaceholder}
                        disabledDate={(current) =>
                          values.startDatePeriod
                            ? current.isBefore(values.startDatePeriod) ||
                              current.isAfter(moment())
                            : current.isAfter(moment())
                        }
                        onChange={(date) =>
                          setFieldValue("endDatePeriod", date)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={16}>
                <Col span={12}>
                  <Row>
                    <label htmlFor="breedId">
                      {
                        translation.movementAnimalReportModalRequest.form
                          .breedId
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="breedId"
                      value={values.breedId || undefined}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={translation.defaultMultiSelectPlaceholder}
                      onChange={(value) => {
                        setFieldValue("breedId", value);
                      }}
                    >
                      {breeds.map((breed) => (
                        <Select.Option value={breed.id} key={breed.id}>
                          {breed.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <label htmlFor="ownerId">
                      {
                        translation.movementAnimalReportModalRequest.form
                          .ownerId
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="farmOwnerId"
                      value={values.farmOwnerId || undefined}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={translation.defaultMultiSelectPlaceholder}
                      onChange={(value) => {
                        setFieldValue("farmOwnerId", value);
                      }}
                    >
                      {farmOwners.map((owner) => (
                        <Select.Option value={owner.id} key={owner.id}>
                          {owner.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={16}>
                <Col span={24}>
                  <Row>
                    <label htmlFor="supplierId">
                      {
                        translation.movementAnimalReportModalRequest.form
                          .supplierId
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="supplierId"
                      value={values.supplierId || undefined}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={translation.defaultMultiSelectPlaceholder}
                      onChange={(value) => {
                        setFieldValue("supplierId", value);
                      }}
                    >
                      {suppliers.map((owner) => (
                        <Select.Option value={owner.id} key={owner.id}>
                          {owner.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <CustomDivider dashed title="EXTENSÃO" />
              <Row type="flex">
                <Col span={24}>
                  <ExtensionContainer>
                    <ExtensionButton type="submit">
                      <ExportPdfIcon />
                    </ExtensionButton>
                  </ExtensionContainer>
                </Col>
              </Row>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default ExportReportMovementAnimalsModal;
