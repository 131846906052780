import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TroughFeedingAssessmentContextProvider } from "../../contexts/troughFeedingAssessmentContext";
import useTroughFeedingAssessmentContext from "../../hooks/useTroughFeedingAssessmentContext";
import { Creators as ReportActions } from "../../store/ducks/report";

import { Col, Icon, Row } from "antd";
import { CardCustom, Title } from "./styles";

import ButtonStandard from "../../components/utils/button";
import TroughFeedingAssessmentTable from "./table";
// Services
import { GenerateReport } from "../lot/listProduction/styles";

function TroughFeedingAssessmentLayout() {
  const { translation } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const { openDrawer, fetchData, tableProperties } =
    useTroughFeedingAssessmentContext();

  function handleRefresh() {
    fetchData(tableProperties.tableSorters, tableProperties.search);
  }

  function handleCreateNewRecord() {
    openDrawer();
  }

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between" align="middle">
        <Col span={8}>
          <Title>{translation.troughFeedingAssessment.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <GenerateReport
                onClick={() =>
                  dispatch(
                    ReportActions.showModal("ReportPicketFeedingAssessment")
                  )
                }
              >
                Planilha de Fornecimento
              </GenerateReport>
            </Col>
            <Col>
              <ButtonStandard
                buttonType="type8"
                onClick={handleCreateNewRecord}
              >
                {translation.troughFeedingAssessment.buttonNew}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <TroughFeedingAssessmentTable />
        </Col>
      </Row>
    </CardCustom>
  );
}

function TroughFeedingAssessment() {
  return (
    <TroughFeedingAssessmentContextProvider>
      <TroughFeedingAssessmentLayout />
    </TroughFeedingAssessmentContextProvider>
  );
}

export default TroughFeedingAssessment;
