export const Types = {
  RESET_DATA: "report/RESET_DATA",
  SHOW_MODAL: "report/SHOW_MODAL",
  HIDE_MODAL: "report/HIDE_MODAL",
  GET_ALL_REPORTS: "report/GET_ALL_REPORTS",
  GET_ALL_REPORTS_SUCCESS: "report/GET_ALL_REPORTS_SUCCESS",
  GET_ALL_REPORTS_ERROR: "report/GET_ALL_REPORTS_ERROR",
  GET_ALL_FAVORITES_REPORTS: "report/GET_ALL_FAVORITES_REPORTS",
  GET_ALL_FAVORITES_REPORTS_SUCCESS: "report/GET_ALL_FAVORITES_REPORTS_SUCCESS",
  GET_ALL_FAVORITES_REPORTS_ERROR: "report/GET_ALL_FAVORITES_REPORTS_ERROR",
  FAVORITE_REPORT: "report/FAVORITE_REPORT",
};

export const Creators = {
  showModalWithReports: (
    title = null,
    reports = null,
    reportSearch = null,
    reportColumns = null,
    ids = []
  ) => ({
    type: Types.SHOW_MODAL,
    payload: {
      title,
      reports,
      reportSearch,
      reportColumns,
      ids,
    },
  }),
  showModal: (
    title = null,
    reportSearch = null,
    reportColumns = null,
    ids = []
  ) => ({
    type: Types.SHOW_MODAL,
    payload: {
      title,
      reportSearch,
      reportColumns,
      ids,
    },
  }),
  hideModal: () => ({
    type: Types.HIDE_MODAL,
    payload: {},
  }),
  switchFavoriteReport: (report) => ({
    type: Types.FAVORITE_REPORT,
    payload: {
      report,
    },
  }),
  getAllReports: (groupId, farmId) => ({
    type: Types.GET_ALL_REPORTS,
    payload: {
      groupId,
      farmId,
    },
  }),
  getAllReportsSuccess: (data) => ({
    type: Types.GET_ALL_REPORTS_SUCCESS,
    payload: {
      data,
    },
  }),
  getAllReportsError: () => ({
    type: Types.GET_ALL_REPORTS_ERROR,
    payload: {},
  }),
  getAllReportsFavorites: (groupId, farmId) => ({
    type: Types.GET_ALL_FAVORITES_REPORTS,
    payload: {
      groupId,
      farmId,
    },
  }),
  getAllReportsFavoritesSuccess: (data) => ({
    type: Types.GET_ALL_FAVORITES_REPORTS_SUCCESS,
    payload: {
      data,
    },
  }),
  getAllReportsFavoritesError: () => ({
    type: Types.GET_ALL_FAVORITES_REPORTS_ERROR,
    payload: {},
  }),
};

const INITIAL_STATE = {
  modalVisible: false,
  title: null,
  reports: [
    {
      name: "Relatório de listagem de animais (Reprodução)",
      code: "ListAnimalReportReproduction",
      fileName: "BovExo_relatorio_listagem_animais_reproducao",
      exportExtensions: ["xlsx"],
      isByTemplate: true,
      hasParameters: false,
    },
    {
      name: "Relatório de listagem de animais (Engorda)",
      code: "ListAnimalReportFatten",
      fileName: "BovExo_relatorio_listagem_animais_engorda",
      exportExtensions: ["xlsx"],
      isByTemplate: true,
      hasParameters: false,
    },
    {
      name: "Relatório para importação de pesagens",
      code: "AnimalWeightImportReport",
      fileName: "BovExo_relatorio_importacao_pesagens",
      exportExtensions: ["xlsx"],
      isByTemplate: true,
      hasParameters: false,
    },
  ],
  reportSearch: "lot",
  reportColumns: null,
  favoriteReports: [],
  allReports: [],
  isLoading: false,
  ids: [],
  isModalMovementReportVisible: false,
  isModalNutritionalConsumptionReportVisible: false,
  isModalConfinementResultReportVisible: false,
  isModalFeedingSheetReportVisible: false,
  isModalAnimalWeightHistoryReportVisible: false,
  isModalFarmSaleReportVisible: false,
  isModalFinancialCashFlowReportVisible: false,
};

export default function report(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SHOW_MODAL:
      const { title, reports, reportSearch, reportColumns, ids } =
        action.payload;
      if (title === "ReportMovementAnimals") {
        return {
          ...state,
          isModalMovementReportVisible: true,
        };
      } else if (title === "ReportNutritionalConsumption") {
        return {
          ...state,
          isModalNutritionalConsumptionReportVisible: true,
        };
      } else if (title === "IntensiveResultReport") {
        return {
          ...state,
          isModalConfinementResultReportVisible: true,
        };
      } else if (title === "ReportPicketFeedingAssessment") {
        return {
          ...state,
          isModalFeedingSheetReportVisible: true,
        };
      } else if (title === "ReportAnimalWeightHistory") {
        return {
          ...state,
          isModalAnimalWeightHistoryReportVisible: true,
        };
      } else if (title === "FarmSalesReport") {
        return {
          ...state,
          isModalFarmSaleReportVisible: true,
        };
      } else if (title === "FinancialCashFlowReport") {
        return {
          ...state,
          isModalFinancialCashFlowReportVisible: true,
        };
      } else {
        return {
          ...state,
          title,
          reports: reports != null ? reports : state.reports,
          reportSearch,
          reportColumns,
          ids,
          modalVisible: true,
        };
      }
    case Types.HIDE_MODAL:
      return {
        ...state,
        isModalMovementReportVisible: false,
        isModalNutritionalConsumptionReportVisible: false,
        isModalConfinementResultReportVisible: false,
        isModalFeedingSheetReportVisible: false,
        isModalAnimalWeightHistoryReportVisible: false,
        isModalFarmSaleReportVisible: false,
        isModalFinancialCashFlowReportVisible: false,
        modalVisible: false,
      };
    case Types.FAVORITE_REPORT:
      const { report: favoriteReport } = action.payload;
      let newFavoriteReports = [...state.favoriteReports];
      if (
        newFavoriteReports.filter(
          (report) => report.name === favoriteReport.name
        ).length > 0
      ) {
        newFavoriteReports = newFavoriteReports.filter(
          (report) => report.name !== favoriteReport.name
        );
      } else {
        newFavoriteReports.push(favoriteReport);
      }
      return {
        ...state,
        favoriteReports: Array.from(new Set(newFavoriteReports)),
      };
    case Types.GET_ALL_REPORTS:
    case Types.GET_ALL_FAVORITES_REPORTS:
      return {
        ...state,
        isLoading: true,
      };
    case Types.GET_ALL_REPORTS_SUCCESS:
      return {
        ...state,
        allReports: action.payload.data,
        isLoading: false,
      };
    case Types.GET_ALL_FAVORITES_REPORTS_SUCCESS:
      return {
        ...state,
        favoriteReports: action.payload.data,
        isLoading: false,
      };
    case Types.GET_ALL_REPORTS_ERROR:
      return {
        ...state,
        allReports: [],
        isLoading: false,
      };
    case Types.GET_ALL_FAVORITES_REPORTS_ERROR:
      return {
        ...state,
        favoriteReports: [],
        isLoading: false,
      };
    default:
      return state;
  }
}
