import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FinancialProvider } from "../../contexts/financialContext";

import { Col, Icon, Row, Tabs } from "antd";
import ButtonStandard from "../../components/utils/button";
import { Body, Container, PageTitle } from "./styles";

import FinancialIcon from "../../components/admin/sidebar/icons/financial";
import AllowedComponentTo from "../../components/utils/allowedComponentTo";
import useModuleContext from "../../hooks/useModuleContext";
import FinancialDashboard from "./dashboard";
import FinancialCashFlow from "./tabs/cashFlow";
import FinancialCosting from "./tabs/costing";
import FarmProductionSell from "./tabs/farmProductionSell";
import ResultCenterAnalysis from "./tabs/resultCenterAnalysis";
import FinancialTransactions from "./tabs/transactions";

const Financial = () => {
  const [isDashboardVisible, setIsDashboardVisible] = useState(true);
  const [tabSelected, setTabSelected] = useState("4");

  const { isAllowedRoles } = useModuleContext();

  // Redux Variables
  const { translation } = useSelector((state) => state.app);

  const disableForTechnician = !isAllowedRoles(["Technician"], true);

  // Methods
  const handleTabClick = (key, e) => {
    setTabSelected(key);
  };

  return (
    <FinancialProvider>
      <Container>
        <Body>
          {/* Header */}
          <Row type="flex" style={{ marginBottom: 10 }}>
            <Col span={24}>
              <PageTitle>
                <FinancialIcon />
                <span>{translation.sidebar.financial}</span>
              </PageTitle>
            </Col>
          </Row>
          {/* Dashboard */}
          <AllowedComponentTo roles={["Technician"]} reverse hide>
            <>
              <Row type="flex" justify="end">
                <Col>
                  <ButtonStandard
                    type="button"
                    buttonType="secondary"
                    onClick={() => setIsDashboardVisible((old) => !old)}
                  >
                    {isDashboardVisible ? (
                      <>
                        <Icon type="fullscreen-exit" /> Ocultar painéis
                      </>
                    ) : (
                      <>
                        <Icon type="fullscreen" /> Exibir painéis
                      </>
                    )}
                  </ButtonStandard>
                </Col>
              </Row>
              <Row type="flex" style={{ marginTop: 10, marginBottom: 10 }}>
                <Col span={24}>
                  <FinancialDashboard visible={isDashboardVisible} />
                </Col>
              </Row>
            </>
          </AllowedComponentTo>
          {/* Tabs */}
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Tabs
                type="card"
                defaultActiveKey="0"
                activeKey={tabSelected}
                onTabClick={handleTabClick}
              >
                <Tabs.TabPane
                  tab={<span>{translation.financial.tabs.animalSell}</span>}
                  key="4"
                >
                  <FarmProductionSell />
                </Tabs.TabPane>

                {!disableForTechnician && (
                  <Tabs.TabPane
                    tab={<span>{translation.financial.tabs.transactions}</span>}
                    key="3"
                  >
                    <FinancialTransactions />
                  </Tabs.TabPane>
                )}
                {!disableForTechnician && (
                  <Tabs.TabPane
                    tab={
                      <span>
                        {translation.financial.tabs.resultCenterAnalysis}
                      </span>
                    }
                    key="5"
                  >
                    <ResultCenterAnalysis />
                  </Tabs.TabPane>
                )}
                {!disableForTechnician && (
                  <Tabs.TabPane
                    tab={<span>{translation.financial.tabs.costing}</span>}
                    key="0"
                  >
                    <FinancialCosting />
                  </Tabs.TabPane>
                )}
                {!disableForTechnician && (
                  <Tabs.TabPane
                    tab={<span>{translation.financial.tabs.cashFlow}</span>}
                    key="1"
                  >
                    <FinancialCashFlow />
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Body>
      </Container>
    </FinancialProvider>
  );
};

export default Financial;
