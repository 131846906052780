import React, { createContext, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as ReportActions } from "../../store/ducks/report";
import { Creators as AppActions } from "../../store/ducks/app";
import { notification } from "antd";
import {
  processReport,
  processReportXlsxFromTemplate,
} from "../../services/reportService";
import moment from "moment";
import ExportFinancialTransactionModal from "../../components/modals/exportFinancialTransaction";
import ExportReportMovementAnimalsModal from "../../components/modals/exportReportMovementAnimals";
import ExportReportNutritionalConsumptionModal from "../../components/modals/exportReportNutritionalConsumption";
import ConfinementResultReportModal from "../../components/modals/confinementResultReport";
import { ExportReportTroughFeedingAssessment } from "../../components/modals/exportReportTroughFeedingAssessment";
import ReportAnimalWeightHistoryModal from "../../components/modals/reportAnimalWeightHistory";
import FarmSalesReportModal from "../../components/modals/farmSalesReport";
import ExportReportFinancialCashFlowModal from "../../components/modals/exportReportFinancialCashFlow";

export const ReportContext = createContext({
  favoriteReports: [],
  groupReports: [],
  handleRequestReport: (report) => {},
});

export const ReportContextProvider = ({ children }) => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { favoriteReports, allReports } = useSelector((state) => state.report);

  const dispatch = useDispatch();

  const groupReports = useMemo(() => {
    return allReports.reduce((result, item) => {
      const { entity } = item;
      // If the entity key doesn't exist, initialize it with an empty array
      if (!result[entity]) {
        result[entity] = [];
      }
      // Push the name into the corresponding entity array
      result[entity].push({ ...item });
      return result;
    }, {});
  }, [allReports]);

  const generateDefaultFileName = (string) => {
    const dateCurrent = moment();
    return `${string
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join("_")}_${dateCurrent.format("DDMMYYYY")}_${dateCurrent.format(
      "HHmm"
    )}`;
  };

  const handleGetFile = useCallback(
    async (
      report,
      isByTemplate = false,
      fileNameP = null,
      reportType,
      reportSearch
    ) => {
      notification.info({
        message:
          "O seu relatório está sendo preparado e logo será feito o download!",
      });
      try {
        const { name: reportName } = report;
        const reportFileName = fileNameP
          ? fileNameP
          : generateDefaultFileName(translation.reports.titles[reportName]);

        let data = null;
        if (isByTemplate === true) {
          const { data: result } = await processReportXlsxFromTemplate({
            groupId,
            farmId,
            reportName,
            reportSearch,
          });
          data = result;
        } else {
          const { data: result } = await processReport({
            groupId,
            farmId,
            reportName,
            reportType,
            reportSearch,
          });
          data = result;
        }
        if (data != null) {
          const fileName =
            reportType === "PDF"
              ? `${reportFileName}.pdf`
              : `${reportFileName}.xlsx`;
          const downloadUrl = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/x-download",
            })
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();

          notification.success({
            message: "Relatório foi gerado com sucesso.",
          });
        }
      } catch (error) {
        notification.error({
          message:
            "Houve um erro ao gerar relatório. Entre em contato com o suporte",
        });
      }
    },
    [farmId, groupId, translation.reports.titles]
  );

  const handleRequestReport = useCallback(
    (report) => {
      const { name } = report;
      switch (name) {
        case "SimpleAnimalReport":
          handleGetFile(report, false, null, "PDF", "animal");
          break;
        case "AnimalWeightImportReport":
          handleGetFile(report, true, null, "EXCEL", "animal");
          break;
        case "ListAnimalReportReproduction":
        case "ListAnimalReportFatten":
          handleGetFile(report, true, null, "EXCEL");
          break;
        case "FinancialTransactionsReport":
          dispatch(AppActions.showModalExportTransactions());
          break;
        default:
          dispatch(ReportActions.showModal(name));
          break;
      }
    },
    [dispatch, handleGetFile]
  );

  useEffect(() => {
    dispatch(ReportActions.getAllReports(groupId, farmId));
    dispatch(ReportActions.getAllReportsFavorites(groupId, farmId));
  }, [dispatch, farmId, groupId]);

  return (
    <ReportContext.Provider
      value={{
        groupReports,
        favoriteReports,
        handleRequestReport,
      }}
    >
      {children}
      <FarmSalesReportModal />
      <ExportFinancialTransactionModal />
      <ExportReportMovementAnimalsModal />
      <ExportReportNutritionalConsumptionModal />
      <ConfinementResultReportModal />
      <ExportReportTroughFeedingAssessment />
      <ReportAnimalWeightHistoryModal />
      <ExportReportFinancialCashFlowModal />
    </ReportContext.Provider>
  );
};
