import { Col, Icon, notification, Row, Tabs } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportIconGray } from "../../components/admin/sidebar/icons/reports";
import { useReportContext } from "../../hooks/useReportContext";
import { updateFavoriteReports } from "../../services/reportService";
import { Creators as ReportActions } from "../../store/ducks/report";
import { Body, Container, PageTitle } from "./styles";

// import { Container } from './styles';

const REPORT_ONLY_CONFINAMENT = [
  "ReportNutritionalConsumption",
  "IntensiveResultReport",
  "ReportPicketFeedingAssessment",
];

function Reports() {
  const [tabSelected, setTabSelected] = useState("1");

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId, confinement: isFarmConfinement },
  } = useSelector((state) => state.app);

  const { favoriteReports, groupReports, handleRequestReport } =
    useReportContext();

  const dispatch = useDispatch();

  // Methods
  const handleTabClick = (key, e) => {
    setTabSelected(key);
  };

  const handleSelectFavorite = useCallback(
    async (report) => {
      try {
        await updateFavoriteReports({
          groupId,
          farmId,
          reportId: report.id,
        });
        dispatch(ReportActions.switchFavoriteReport(report));
      } catch (error) {
        notification.error({
          message: translation.reports.messages.errorUpdateFavorite,
        });
      }
    },
    [
      dispatch,
      farmId,
      groupId,
      translation.reports.messages.errorUpdateFavorite,
    ]
  );

  return (
    <Container>
      <Body>
        {/* Header */}
        <Row type="flex" style={{ marginBottom: 16 }}>
          <Col span={24}>
            <PageTitle>
              <ReportIconGray />
              <span>{translation.sidebar.report}</span>
            </PageTitle>
          </Col>
        </Row>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs
              type="card"
              defaultActiveKey="1"
              activeKey={tabSelected}
              onTabClick={handleTabClick}
            >
              <Tabs.TabPane
                tab={<span>{translation.reports.tabs.favorite}</span>}
                key="1"
              >
                <Row
                  type="flex"
                  justify="space-around"
                  align="middle"
                  gutter={8}
                >
                  {favoriteReports
                    .filter(
                      (report) =>
                        (REPORT_ONLY_CONFINAMENT.includes(report.name) &&
                          isFarmConfinement) ||
                        (isFarmConfinement &&
                          !REPORT_ONLY_CONFINAMENT.includes(report.name)) ||
                        (!isFarmConfinement &&
                          !REPORT_ONLY_CONFINAMENT.includes(report.name))
                    )
                    .map((report) => {
                      const { name } = report;
                      return (
                        <Col
                          key={name}
                          style={{
                            padding: 8,
                            border: "1px solid #4b4b4b",
                            borderRadius: 8,
                            marginTop: 4,
                            marginBottom: 4,
                          }}
                        >
                          <Row type="flex" align="middle" gutter={8}>
                            <Col>
                              <button
                                style={{ all: "unset", cursor: "pointer" }}
                                onClick={() => handleSelectFavorite(report)}
                              >
                                <Icon
                                  type="star"
                                  style={{
                                    fontSize: 24,
                                  }}
                                  theme={
                                    favoriteReports.findIndex(
                                      (fReport) => fReport.name === name
                                    ) >= 0
                                      ? "filled"
                                      : "outlined"
                                  }
                                />
                              </button>
                            </Col>
                            <Col>
                              <button
                                style={{ all: "unset", cursor: "pointer" }}
                                onClick={() => handleRequestReport(report)}
                              >
                                <strong>
                                  {translation.reports.titles[name]}
                                </strong>
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                </Row>
              </Tabs.TabPane>
              {Object.entries(groupReports).map(([entity, reports]) => {
                return (
                  <Tabs.TabPane
                    tab={
                      <span>{translation.reports.tabs[entity] ?? entity}</span>
                    }
                    key={entity}
                  >
                    <Row
                      type="flex"
                      justify="space-around"
                      align="middle"
                      gutter={8}
                    >
                      {reports
                        .filter(
                          (report) =>
                            (REPORT_ONLY_CONFINAMENT.includes(report.name) &&
                              isFarmConfinement) ||
                            (isFarmConfinement &&
                              !REPORT_ONLY_CONFINAMENT.includes(report.name)) ||
                            (!isFarmConfinement &&
                              !REPORT_ONLY_CONFINAMENT.includes(report.name))
                        )
                        .map((report) => {
                          const { name } = report;
                          return (
                            <Col
                              key={name}
                              style={{
                                padding: 8,
                                border: "1px solid #4b4b4b",
                                borderRadius: 8,
                                marginTop: 4,
                                marginBottom: 4,
                              }}
                            >
                              <Row type="flex" align="middle" gutter={8}>
                                <Col>
                                  <button
                                    style={{ all: "unset", cursor: "pointer" }}
                                    onClick={() => handleSelectFavorite(report)}
                                  >
                                    <Icon
                                      type="star"
                                      style={{
                                        fontSize: 24,
                                      }}
                                      theme={
                                        favoriteReports.findIndex(
                                          (fReport) => fReport.name === name
                                        ) >= 0
                                          ? "filled"
                                          : "outlined"
                                      }
                                    />
                                  </button>
                                </Col>
                                <Col>
                                  <button
                                    style={{ all: "unset", cursor: "pointer" }}
                                    onClick={() => handleRequestReport(report)}
                                  >
                                    <strong>
                                      {translation.reports.titles[name] ?? name}
                                    </strong>
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                    </Row>
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </Body>
    </Container>
  );
}

export default Reports;
