import React from "react";
import icon from "./favorite-report-icon.png";

// import { Container } from './styles';

function FavoriteReportIcon() {
  return <img src={icon} alt="Ícone de relatórios favoritos" />;
}

export default FavoriteReportIcon;
