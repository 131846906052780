import { call, put } from "redux-saga/effects";
import { Creators as ReportActions } from "../ducks/report";
import {
  getAllFavoriteReports,
  getAllReports,
} from "../../services/reportService";

export function* indexAllReports(action) {
  try {
    const {
      data: { results: reports },
    } = yield call(getAllReports, action.payload);
    yield put(ReportActions.getAllReportsSuccess(reports));
  } catch (error) {
    yield put(ReportActions.getAllReportsError());
  }
}

export function* indexAllReportsFavorites(action) {
  try {
    const {
      data: { results: reports },
    } = yield call(getAllFavoriteReports, action.payload);
    yield put(ReportActions.getAllReportsFavoritesSuccess(reports));
  } catch (error) {
    yield put(ReportActions.getAllReportsFavoritesError());
  }
}
