import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as BreedActions } from "../../../store/ducks/breed";
import { Creators as LotActions } from "../../../store/ducks/lot";

import moment from "moment";
import "moment/locale/en-nz";
import "moment/locale/es";
import "moment/locale/pt-br";

import { Col, DatePicker, Divider, Drawer, Input, Row, Select } from "antd";
import { Formik } from "formik";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import ButtonStandard from "../../utils/button";
import InfoTooltip from "../../utils/infoTooltip";
import SelectLotProduction from "../../utils/selectLotProduction";
import { Container, Footer } from "./styles";

const validationSchema = Yup.object().shape({
  breedId: Yup.string().required(),
  animalFarmFunction: Yup.string().required(),
  gender: Yup.string().required(),
  birthday: Yup.date().required().max(new Date()),
  estimatedWeight: Yup.number().positive().required(),
  amount: Yup.number().positive().required(),
  purchaseCost: Yup.number().positive().required(),
  prefixIdentification: Yup.string().when(["shouldCreateLotEntry"], {
    is: (shouldCreateLotEntry) => shouldCreateLotEntry === true,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
  lotDestinationId: Yup.string().when(["shouldCreateLotEntry"], {
    is: (shouldCreateLotEntry) => shouldCreateLotEntry === true,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
});

const { Option } = Select;

const { Group: InputGroup } = Input;

const dateFormat = "DD/MM/YYYY";

const NewLotItem = () => {
  const formikRef = useRef();

  const {
    app: {
      translation,
      groupSelected,
      farmSelected,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    lot: { drawerLotItemReceiptVisible, lotItem },
    breed: { data: breeds },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const fetchData = (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = ""
  ) => {
    dispatch(BreedActions.indexBreedActive(groupId, farmId));
  };

  const closeDrawer = () => {
    formikRef.current.resetForm({
      id: null,
      breedId: null,
      breedName: null,
      gender: null,
      birthday: null,
      animalFarmFunction: "fatten",
      animalReproductionCategory: null,
      estimatedWeight: "",
      amount: "",
      purchaseCost: "",
      shouldCreateLotEntry: false,
      prefixIdentification: null,
      lotDestinationId: null,
      numbersArray: [],
      numbers: "",
      numbersInserted: 0,
    });
    dispatch(LotActions.hideLotItemReceipt());
  };

  const handleSubmitModalForm = async (values, actions) => {
    if (values.id === null) {
      values.id = Math.random();
      handleAddNewLotItem(values);
    } else {
      handleUpdateLotItem(values);
    }
    actions.resetForm({
      id: null,
      breedId: null,
      breedName: null,
      animalFarmFunction: "fatten",
      animalReproductionCategory: null,
      gender: null,
      birthday: null,
      estimatedWeight: "",
      amount: "",
      purchaseCost: "",
      shouldCreateLotEntry: false,
      prefixIdentification: null,
      lotDestinationId: null,
      numbersArray: [],
      numbers: "",
      numbersInserted: 0,
    });
  };

  const handleAddNewLotItem = (lotItem) => {
    dispatch(LotActions.addNewLotReceiptItem(lotItem, false));
  };

  const handleUpdateLotItem = (lotItem) => {
    dispatch(LotActions.updateLotItem(lotItem));
  };

  const handleDrawerVisible = (visible) => {
    if (visible) {
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        fetchData(groupSelected, farmSelected);
      }
    }
  };

  return (
    <Drawer
      title={translation.lot.receipt.finalize.newLotItem.title}
      width={550}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerLotItemReceiptVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Container>
        <Formik
          ref={formikRef}
          enableReinitialize={true}
          initialValues={lotItem}
          onSubmit={handleSubmitModalForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div id="drawerForm">
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span style={{ fontSize: "14px", color: "#000" }}>
                      {translation.lot.receipt.finalize.newLotItem.description}:
                    </span>
                  </Col>
                </Row>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          errors.breedId && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.lot.receipt.finalize.newLotItem.breed}*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="breedId"
                        value={
                          values.breedId != null ? values.breedId : undefined
                        }
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem.breed
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          setFieldValue("breedId", value);
                          setFieldValue(
                            "breedName",
                            breeds.filter((breed) => breed.id === value)[0].name
                          );
                        }}
                      >
                        {Object.entries(breeds).length > 0 &&
                          breeds.map((breed) => (
                            <Option key={breed.id} value={breed.id}>
                              {breed.name}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  style={{ marginTop: "13px" }}
                  align="middle"
                  gutter={8}
                >
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row>
                      <label
                        className={
                          errors.gender && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.lot.receipt.finalize.newLotItem.gender}*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="gender"
                        value={
                          values.gender == null ? undefined : values.gender
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          setFieldValue("gender", value);
                        }}
                      >
                        <Option key="M" value="M">
                          {translation.animal.details.male}
                        </Option>
                        <Option key="F" value="F">
                          {translation.animal.details.female}
                        </Option>
                      </Select>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row>
                      <label
                        className={
                          errors.animalFarmFunction && submitCount > 0
                            ? "formLabel error"
                            : "formLabel"
                        }
                      >
                        {
                          translation.lot.receipt.finalize.newLotItem
                            .animalFarmFunction
                        }
                        *
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="animalFarmFunction"
                        value={values.animalFarmFunction || undefined}
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          setFieldValue("animalFarmFunction", value);
                        }}
                      >
                        <Option value="reproduction">Reprodução</Option>
                        <Option value="fatten">Engorda</Option>
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" gutter={8}>
                  {values.animalFarmFunction === "reproduction" && (
                    <Col span={12}>
                      <Row>
                        <label
                          className={
                            errors.animalReproductionCategory && submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {
                            translation.lot.receipt.finalize.newLotItem
                              .animalReproductionCategory
                          }
                          *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="animalReproductionCategory"
                          value={values.animalReproductionCategory || undefined}
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) =>
                            setFieldValue("animalReproductionCategory", value)
                          }
                        >
                          {(values.gender === "F" ||
                            values.gender === "Female") && (
                            <Select.Option key="nulipara" value="nulipara">
                              Nulípara
                            </Select.Option>
                          )}
                          {(values.gender === "F" ||
                            values.gender === "Female") && (
                            <Select.Option key="novilha" value="novilha">
                              Novilha
                            </Select.Option>
                          )}
                          {(values.gender === "F" ||
                            values.gender === "Female") && (
                            <Select.Option key="primipara" value="primipara">
                              Primípara
                            </Select.Option>
                          )}
                          {(values.gender === "F" ||
                            values.gender === "Female") && (
                            <Select.Option
                              key="secundipara"
                              value="secundipara"
                            >
                              Secundípara
                            </Select.Option>
                          )}
                          {(values.gender === "F" ||
                            values.gender === "Female") && (
                            <Select.Option key="multipara" value="multipara">
                              Multípara
                            </Select.Option>
                          )}
                          {(values.gender === "M" ||
                            values.gender === "Male") && (
                            <Select.Option value="reprodutor">
                              Reprodutor
                            </Select.Option>
                          )}
                        </Select>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row>
                      <label
                        className={
                          errors.birthday && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.lot.receipt.finalize.newLotItem.birthday}*
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        style={{ width: "100%" }}
                        allowClear={false}
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem
                            .birthdayPlaceHolder
                        }
                        value={
                          values.birthday != null
                            ? moment(values.birthday, "YYYY-MM-DD")
                            : null
                        }
                        format={dateFormat}
                        name="birthday"
                        onChange={(dateMoment, dateString) => {
                          if (dateMoment <= moment() && dateMoment !== null) {
                            setFieldValue("birthday", dateMoment);
                          }
                        }}
                      />
                    </Row>
                  </Col>
                  <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                    <Row type="flex" align="middle" justify="center">
                      <span>-</span>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Row>
                      <label>Idade (dias/meses/anos)</label>
                    </Row>
                    <Row>
                      <Col>
                        <InputGroup compact>
                          <Input
                            type="number"
                            style={{ width: 80, textAlign: "center" }}
                            name="ageDay"
                            min="0"
                            placeholder="Dias"
                            value={
                              values.birthday != null
                                ? moment().diff(values.birthday, "days")
                                : null
                            }
                            onChange={(e) => {
                              setFieldValue(
                                "birthday",
                                moment().subtract(
                                  e.target.value > 5478 ? 5478 : e.target.value,
                                  "days"
                                )
                              );
                            }}
                          />
                          <Input
                            style={{
                              width: 80,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageMonth"
                            min="0"
                            placeholder="Meses"
                            value={
                              values.birthday != null
                                ? moment().diff(values.birthday, "months")
                                : null
                            }
                            onChange={(e) => {
                              setFieldValue(
                                "birthday",
                                moment().subtract(
                                  e.target.value > 180 ? 180 : e.target.value,
                                  "months"
                                )
                              );
                            }}
                          />
                          <Input
                            style={{
                              width: 80,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageYear"
                            min="0"
                            placeholder="Anos"
                            value={
                              values.birthday != null
                                ? moment().diff(values.birthday, "years", false)
                                : null
                            }
                            onChange={(e) => {
                              setFieldValue(
                                "birthday",
                                moment().subtract(
                                  e.target.value > 15 ? 15 : e.target.value,
                                  "years"
                                )
                              );
                            }}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          errors.estimatedWeight && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {
                          translation.lot.receipt.finalize.newLotItem
                            .estimatedWeight
                        }
                        *
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem
                            .estimatedWeightPlaceHolder
                        }
                        value={
                          values.estimatedWeight ? values.estimatedWeight : ""
                        }
                        addonAfter="Kg"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        name="estimatedWeight"
                        onValueChange={({ floatValue }) => {
                          setFieldValue(
                            "estimatedWeight",
                            floatValue
                              ? floatValue < 1000
                                ? floatValue
                                : 1000
                              : null
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          errors.amount && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.lot.receipt.finalize.newLotItem.amount}*
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem
                            .amoutPlaceHolder
                        }
                        value={values.amount ? values.amount : ""}
                        name="amount"
                        min={0}
                        decimalScale={0}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          setFieldValue(
                            "amount",
                            floatValue
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : null
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          errors.purchaseCost && submitCount > 0 ? "error" : ""
                        }
                      >
                        {
                          translation.lot.receipt.finalize.newLotItem
                            .purchaseCost
                        }
                        *
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem
                            .purchaseCostPlaceHolder
                        }
                        value={values.purchaseCost ? values.purchaseCost : ""}
                        name="purchaseCost"
                        min={0}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        addonBefore="R$"
                        style={{ width: "100%" }}
                        onValueChange={({ floatValue }) => {
                          setFieldValue(
                            "purchaseCost",
                            floatValue
                              ? floatValue > 99999
                                ? 99999
                                : floatValue
                              : null
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {values.shouldCreateLotEntry && (
                  <>
                    <Divider dashed>
                      INFORMAÇÕES PARA CADASTRO DE ANIMAIS
                    </Divider>
                    <Row type="flex" gutter={8}>
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              submitCount > 0 &&
                              errors.prefixIdentification != null
                                ? "error"
                                : ""
                            }
                            htmlFor="prefixIdentification"
                          >
                            {translation.lotItemEntry.form.prefixIdentification}
                            *{" "}
                            <InfoTooltip
                              title={
                                'Adicione o Prefixo a ser utilizado na identificação provisória dos animais. A partir deste prefixo, serão gerados os animais com a identificação  "Prefixo" + "Odem" (001, 002, 003).'
                              }
                            />
                          </label>
                        </Row>
                        <Row>
                          <Input
                            value={values.prefixIdentification || ""}
                            placeholder={translation.defaultPlaceholder}
                            maxLength={10}
                            onChange={(e) =>
                              setFieldValue(
                                "prefixIdentification",
                                e.target.value
                              )
                            }
                          />
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              submitCount > 0 && errors.lotDestinationId != null
                                ? "error"
                                : ""
                            }
                            htmlFor="lotDestinationId"
                          >
                            {translation.lotItemEntry.form.lotDestinationId}*
                          </label>
                        </Row>
                        <Row>
                          <SelectLotProduction
                            id={"lotDestinationId"}
                            name="lotDestinationId"
                            value={values.lotDestinationId || undefined}
                            onDeselect={() =>
                              setFieldValue("lotDestinationId", null)
                            }
                            onChange={(value) => {
                              setFieldValue("lotDestinationId", value);
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  />
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Container>
    </Drawer>
  );
};
export default NewLotItem;
